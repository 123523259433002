import { CreateUpdateFields } from "./fieldsCommon";

const Search = `
    id
    field1
    field2
    field3
    field4
    field5
    field6
    field7
`;

const News = `
    id
    title
    sourcename
    link
    originallink
    description
    pubDate
    bookmarkId
`;

const Law = `
    id
    col1
    col2
    title
    link
    bookmarkId
`;

const InfoRetrievalBookmark = `
    id
    type
    title
    link
    ${CreateUpdateFields}
`;

export const fieldsInfoRetrieval = { Search, News, Law, InfoRetrievalBookmark };
