import React, { useEffect, useState } from "react";
import InsightsIcon from "@mui/icons-material/Insights";
import { useDataProvider, useTheme, useTranslate } from "react-admin";
import { ChartBox } from "@src/components/charts/ChartBox";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { CircularProgress, Box, Typography } from "@mui/material";
import { BarChart } from "@src/components/charts/BarChart";

export const GuaranteeChartMon = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [data, setData] = useState<any[]>([]);
    const [arrKeys, setKeys] = useState<any[]>([]);
    const [avgTop, setAvgTop] = useState(0);

    useEffect(() => {
        const setChartData = async () => {
            // 실데이터
            const { data } = await dataProvider.callExternalApi("/viewData/viewGuarMon");
            const keys: any[] = []; // for chart keys prop (stacked items)
            keys.push("후원방문");
            keys.push("다단계");

            let avgTT_SUM = 0;

            const adjustedData = data.map((item: any) => {
                const { data } = item;
                const datum = JSON.parse(data);
                const date = datum["STAT_MON"];

                avgTT_SUM += datum["TT_SUM"];

                // x축은 indexBy="date"가 기본값이 날짜
                // y축에 표시할 차트 데이터를 같이 포함하는 데이터를 추가
                return {
                    date,
                    ["후원방문"]: datum["TT_SALE2"],
                    ["다단계"]: datum["TT_SALE1"],
                };
            });
            setAvgTop(avgTT_SUM / data.length);
            setKeys(keys);
            setData(adjustedData);
        };
        setChartData();
    }, []);

    return (
        <div>
            <ChartBox
                //titleIcon={<InsightsIcon color="disabled" fontSize="large" />}
                //titleText="dashboard.guarantee_chart"
                chart={
                    data.length > 0 ? (
                        <BarChart
                            data={data} // { date: 날짜데이터, [y축 항목1]: [항목1 데이터값], [y축 항목2]: [항목2 데이터값] ...  }
                            keys={arrKeys} // [...y축 항목들]
                            colors={["#ff7f00", "#0a6eff", "#d62728", "#80e12a"]}
                            axisRight={{
                                format: (v: any) => `${Math.floor(v / 100000000)}억`,
                                tickValues: 8,
                            }}
                            axisBottom={{
                                legendPosition: "middle",
                                legendOffset: 35,
                                tickSize: 0,
                                tickPadding: 5,
                                tickRotation: 40,
                                //tickRotation: 90,
                                format: (date: string) => {
                                    // 1일인 경우만 날짜 표시
                                    // if (date.slice(6, 8) === "01") {
                                    //     return `${date.slice(0, 4)}-${date.slice(4, 6)}`;
                                    // } else {
                                    //     return "";
                                    // }
                                    return `${date.slice(0, 6)}`;
                                },
                            }}
                            markers={[
                                {
                                    axis: "y",
                                    value: avgTop,
                                    lineStyle: { stroke: "rgba(128,225,42,0.6)", strokeWidth: 2 },
                                    legend: "",
                                    legendOrientation: "vertical",
                                },
                            ]}
                            tooltip={({ id, value, color, data: { date } }: any) => {
                                return (
                                    <ChartTooltip
                                        // id={`${id}, ${date.slice(0, 4)}년 ${date.slice(4, 6)}월 ${date.slice(6, 8)}일`}
                                        id={`${id}: `}
                                        color={color}
                                        content={`${Math.floor(+value / 100000000).toLocaleString()}억`}
                                    />
                                );
                            }}
                            enableGridY="true"
                        />
                    ) : (
                        <CircularProgress />
                    )
                }
            />
        </div>
    );
};
