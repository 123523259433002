import React from "react";
import {
    Create,
    ListButton,
    TopToolbar,
    useCreate,
    useDataProvider,
    useGetIdentity,
    useNotify,
    useRedirect,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { Task, TaskCreateInputs } from "./types";
import { TaskInputs } from "./TaskInputs";

const CreateActions = () => {
    return (
        <TopToolbar>
            <ListButton />
        </TopToolbar>
    );
};

export const TaskCreate = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [create] = useCreate();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();

    const handleSubmit = (data: TaskCreateInputs) => {
        const {
            inbound_at,
            inbound_channel_category_1,
            inbound_channel_category_2,
            customer_inbound_count,
            customer_name,
            customer_type,
            request_title,
            request_category_1,
            request_category_2,
            request_category_3,
            request_description,
        } = data;

        create(
            "task",
            {
                data: {
                    taskInput: {
                        inbound_at,
                        inbound_channel_category_1: "TASK_INBOUND_CHANNEL." + inbound_channel_category_1,
                        inbound_channel_category_2: inbound_channel_category_1 + "." + inbound_channel_category_2,
                        customer_inbound_count: "TASK_CUSTOMER_INBOUND_COUNT." + customer_inbound_count,
                        customer_name,
                        customer_type: "TASK_CUSTOMER_TYPE." + customer_type,
                        request_title,
                        request_category_1: "TASK_REQUEST_TYPE." + request_category_1,
                        request_category_2: request_category_1 + "." + request_category_2,
                        request_category_3: request_category_2 + "." + request_category_3,
                        request_description,
                        create_userid: identity?.id,
                    },
                },
            },
            {
                onSuccess: (task: Task) => {
                    notify("ra.notification.created");
                    // increase the task_status_order of all tasks in the same status as the new task
                    dataProvider
                        .getList("task", {
                            pagination: { page: 1, perPage: 500 },
                            sort: { field: "id", order: "ASC" },
                            filter: { task_status: task.task_status.code },
                        })
                        .then(({ data: tasks }) =>
                            Promise.all(
                                tasks
                                    .filter(oldTask => oldTask.id !== task.id)
                                    .map(oldTask =>
                                        dataProvider.update("task", {
                                            id: oldTask.id,
                                            data: {
                                                taskInput: {
                                                    id: oldTask.id,
                                                    task_status_order: oldTask.task_status_order + 1,
                                                },
                                            },
                                            previousData: null,
                                        }),
                                    ),
                            ),
                        );
                    redirect("list", resource);
                },
            },
        );
    };

    return (
        <Create title={translate("task.addTask")} actions={<CreateActions />} redirect="list">
            <TaskInputs handleSubmit={handleSubmit} />
        </Create>
    );
};
