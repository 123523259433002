import React, { useState } from "react";
import {
    ShowBase,
    ReferenceManyField,
    useRecordContext,
    useRedirect,
    Identifier,
    useTranslate,
    IconButtonWithTooltip,
    useGetIdentity,
    useNotify,
    useDelete,
    useResourceContext,
    useUpdate,
    RaRecord,
    RichTextField,
    RecordContextProvider,
    useDeepCompareEffect,
    usePermissions,
    useRefresh,
} from "react-admin";
import { Box, Dialog, DialogContent, Typography, Divider, useMediaQuery, Theme, Grid } from "@mui/material";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import { TaskAvatar } from "./TaskAvatar";
import { NotesIterator } from "@src/components/notes";
import { DeptSelector } from "./DeptSelector";
// import { convertMsToProgressTime } from "@src/utils/date";
import { useSearchParams } from "react-router-dom";

export const TaskShow = ({
    showInfo: { open, id },
    setShowInfo,
}: {
    showInfo: { open: boolean; id: Identifier };
    setShowInfo: any;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const refresh = useRefresh();
    const handleClose = (removed?: boolean) => {
        const param = searchParams.get("id");

        if (param) {
            searchParams.delete("id");
            setSearchParams(searchParams);
        }
        setShowInfo({
            id,
            open: false,
        });

        if (!removed) {
            refresh();
        }
    };

    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose(false);
            }}
            fullWidth
            maxWidth="md"
            sx={{
                ".MuiDialog-paper": {
                    position: "absolute",
                },
            }}
        >
            <DialogContent>
                {!!id ? (
                    <ShowBase id={id}>
                        <TaskShowContent id={id} handleClose={handleClose} />
                    </ShowBase>
                ) : null}
            </DialogContent>
        </Dialog>
    );
};

const TaskShowContent = ({ id, handleClose }: { id?: Identifier; handleClose: (removed?: boolean) => void }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const { isLoading, permissions } = usePermissions();
    const redirect = useRedirect();
    const resource = useResourceContext();
    const record = useRecordContext();
    const translate = useTranslate();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const [dept, setDept] = useState<string>(record?.response_department?.code ?? "");
    const [updateTask] = useUpdate(
        resource,
        {
            id: record?.id,
            data: {
                taskInput: {
                    task_stash: !record?.task_stash,
                    update_userid: `${identity?.id}(${identity?.fullName})`,
                },
            },
            previousData: record,
        },
        {
            mutationMode: "pessimistic",
            onSuccess: ({ task_stash }: RaRecord) => {
                notify(task_stash ? "notification.stash" : "notification.unstash", { type: "info" });
                handleClose(false);
            },
        },
    );
    const [deleteTask] = useDelete(
        resource,
        {
            id: id as Identifier,
            previousData: record,
        },
        {
            mutationMode: "pessimistic",
            onSuccess: () => {
                notify("ra.notification.deleted", { type: "info", undoable: false });
                handleClose(true);
            },
        },
    );

    useDeepCompareEffect(() => {
        if (record?.response_department?.code !== dept) {
            setDept(record?.response_department?.code ?? "");
        }
    }, [record]);

    const handleMenuToggleStash = () => {
        updateTask();
    };

    const handleDelete = () => {
        deleteTask();
    };
    if (!record || !identity) return null;
    if (isLoading || !permissions.task?.read) return null;

    let progressTimeColor = "#00c694";
    if (record.response_time_to_complete > 0) {
        const days = Math.floor(record.response_time_to_complete / 1000 / 60 / 60 / 24);
        if (days > 4) {
            progressTimeColor = "red";
        } else if (days > 2) {
            progressTimeColor = "orange";
        }
    }

    return (
        <Box display="flex">
            {!isSmall && (
                <Box width={100} display="flex" flexDirection="column" alignItems="center">
                    <TaskAvatar dept={dept} sx={{ mt: 2, mb: 2 }} />
                </Box>
            )}
            <Box ml={isSmall ? 0 : 2} flex="1">
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5">{record.request_title}</Typography>
                    <Box minWidth={150} display="flex" justifyContent="end">
                        {permissions.task?.update && record.task_status.code === "VOC_STATUS_COMPLETE" && (
                            <IconButtonWithTooltip
                                label={translate(record.task_stash ? "common.unstash" : "common.stash")}
                                size="small"
                                onClick={handleMenuToggleStash}
                            >
                                {record.task_stash ? <OutboxIcon /> : <MoveToInboxIcon />}
                            </IconButtonWithTooltip>
                        )}
                        {permissions.task?.update && (
                            <IconButtonWithTooltip
                                label={translate("task.editTask")}
                                size="small"
                                onClick={() => {
                                    redirect(`/task/${id}`);
                                }}
                            >
                                <EditIcon />
                            </IconButtonWithTooltip>
                        )}
                        {permissions.task?.delete && (
                            <IconButtonWithTooltip
                                label={translate("task.deleteTask")}
                                size="small"
                                onClick={handleDelete}
                            >
                                <TrashIcon />
                            </IconButtonWithTooltip>
                        )}
                    </Box>
                </Box>

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.inboundAt")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {format(new Date(Number(record.inbound_at)), "PP", { locale: ko })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.taskStatus")}
                        </Typography>
                        <Typography variant="subtitle1">{record.task_status.code_name}</Typography>
                    </Grid>
                    {record.task_status.code === "VOC_STATUS_COMPLETE" && (
                        <>
                            <Grid item xs={6} sm={6} md={3}>
                                <Typography color="textSecondary" variant="body2">
                                    {translate("task.responseCompleteAt")}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {record.response_complete_at &&
                                        format(new Date(Number(record.response_complete_at)), "PP", { locale: ko })}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={6} sm={6} md={3}>
                                <Typography color="textSecondary" variant="body2">
                                    {translate("task.responseTimeToComplete")}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ color: progressTimeColor }}>
                                    {convertMsToProgressTime(+record.response_time_to_complete)}
                                </Typography>
                            </Grid> */}
                        </>
                    )}
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.responseDepartment")}
                        </Typography>
                        {record.task_stash ? (
                            <Typography variant="subtitle1">{record?.response_department?.code_name ?? ""}</Typography>
                        ) : (
                            <DeptSelector dept={dept} setDept={setDept} />
                        )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.customerType")}
                        </Typography>
                        <Typography variant="subtitle1">{record.customer_type.code_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.customerName")}
                        </Typography>
                        <Typography variant="subtitle1">{record.customer_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.customerInboundCnt")}
                        </Typography>
                        <Typography variant="subtitle1">{record.customer_inbound_count}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.inboundChannel1")}
                        </Typography>
                        <Typography variant="subtitle1">{record.inbound_channel_category_1.code_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.inboundChannel2")}
                        </Typography>
                        <Typography variant="subtitle1">{record.inbound_channel_category_2.code_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.inboundContact")}
                        </Typography>
                        <Typography variant="subtitle1">{record.inbound_contact}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.requestCategory1")}
                        </Typography>
                        <Typography variant="subtitle1">{record.request_category_1.code_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.requestCategory2")}
                        </Typography>
                        <Typography variant="subtitle1">{record.request_category_2.code_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("task.requestCategory3")}
                        </Typography>
                        <Typography variant="subtitle1">{record.request_category_3.code_name}</Typography>
                    </Grid>
                </Grid>

                <Box mt={2} mb={2} style={{ whiteSpace: "pre-line" }}>
                    <Typography color="textSecondary" variant="body2">
                        {translate("common.description")}
                    </Typography>
                    <Typography>{record.task_description}</Typography>
                    <RecordContextProvider value={record}>
                        <RichTextField source="request_description" fullWidth />
                    </RecordContextProvider>
                </Box>

                <Divider />

                <Box mt={2}>
                    <ReferenceManyField
                        target="task_id"
                        reference="task/note"
                        sort={{ field: "create_at", order: "DESC" }}
                    >
                        <NotesIterator reference="task" />
                    </ReferenceManyField>
                </Box>
            </Box>
        </Box>
    );
};
