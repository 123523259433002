import { Card, CardContent, Typography, Box, CircularProgress, Button } from "@mui/material";
import { useTheme, useTranslate } from "react-admin";
import BusinessIcon from "@mui/icons-material/Business";
import { useDataProvider } from "@src/dataProvider";
import { useEffect, useState } from "react";

export const MaccoEvent = () => {
    const [theme] = useTheme();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [maccoEvent, setMaccoEvent] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const setShopData = async () => {
            const { data } = await dataProvider.callExternalApi("/viewData/viewMaccoEvent");
            setMaccoEvent(data.map((item: { data: any }) => JSON.parse(item.data)));
            setIsLoading(false);
        };
        setShopData();
        const interval = setInterval(setShopData, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Box display="flex" alignItems="center" marginBottom="10px" marginLeft="8px" marginTop="15px">
                {/* <Box ml={2} mr={2} display="flex">
                    <BusinessIcon color="disabled" fontSize="large" />
                </Box> */}
                <Typography variant="h6" color="#2f58aa">
                    {translate("dashboard.macco_event")}
                </Typography>
            </Box>
            <Card>
                <CardContent sx={{ minHeight: "300px", maxHeight: "350px", overflowY: "auto" }}>
                    {isLoading && (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {maccoEvent.map((maccoEvent: any, idx: number) => (
                        <Box
                            display="flex"
                            flexDirection="row"
                            key={idx}
                            sx={{
                                margin: "0.5em 0",
                                backgroundColor: theme?.palette?.mode === "dark" ? "#262626" : "#f9fafa",
                                padding: "0.5em 0",
                                borderRadius: "10px",
                                justifyContent: "space-between",
                                //justifyContent: "flex-start",
                            }}
                        >
                            <Box ml={2}>
                                <Typography
                                    sx={{
                                        color: (theme: any) =>
                                            maccoEvent.LAST_YN > 0
                                                ? theme.palette.warning.dark
                                                : theme.palette.grey.A700,
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        fontSize: "1.0rem",
                                    }}
                                >
                                    {`[${maccoEvent.EVT_TYPE}] ${maccoEvent.EVT_TITLE}`}
                                </Typography>
                                <div style={{ display: "flex" }}>
                                    <Typography
                                        sx={{
                                            color: (theme: any) => theme.palette.error.dark,
                                            display: "-webkit-box",
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            fontSize: "0.87rem",
                                            textAlign: "left",
                                        }}
                                    >
                                        {`${maccoEvent.STA_DATE2}`}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: (theme: any) => theme.palette.grey.A700,
                                            display: "-webkit-box",
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            fontSize: "0.80rem",
                                            textAlign: "right",
                                            marginLeft: 1,
                                        }}
                                    >
                                        {`${maccoEvent.EVT_BIGO}`}
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};
