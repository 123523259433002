import { ResponsiveBar } from "@nivo/bar";
import { getNivoTheme } from "@src/layout/themes";
import { useTheme } from "react-admin";

export const BarChart = ({
    data,
    colors = { scheme: "nivo" },
    keys,
    axisRight,
    tooltip,
    valueScale,
    markers,
    indexBy = "date",
    margin = { top: 30, right: 50, bottom: 50, left: 20 },
    ...commonChartProps
}: any) => {
    const [theme] = useTheme();

    // reference - https://nivo.rocks/bar/
    return (
        <ResponsiveBar
            data={data}
            theme={getNivoTheme(theme)}
            indexBy={indexBy}
            keys={keys}
            colors={colors}
            margin={margin}
            tooltip={tooltip}
            padding={0.3}
            valueScale={valueScale}
            indexScale={{ type: "band", round: true }}
            enableGridX={false}
            enableGridY={false}
            enableLabel={false}
            axisBottom={{
                legendPosition: "middle",
                legendOffset: 50,
                tickSize: 0,
                tickPadding: 12,
            }}
            axisLeft={null}
            axisRight={axisRight}
            markers={markers}
            legends={[
                {
                    anchor: "top-left",
                    dataFrom: "keys",
                    direction: "column",
                    itemHeight: 20,
                    itemWidth: 110,
                    toggleSerie: true,
                    translateX: 0,
                },
            ]}
            {...commonChartProps}
        />
    );
};
