import * as React from "react";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import GavelIcon from "@mui/icons-material/Gavel";
import { MenuItemLink, useTranslate } from "react-admin";

const InfoRetrievalMenuItems = ({ dense }: { dense: boolean }) => {
    const translate = useTranslate();

    return (
        <>
            <MenuItemLink
                to="info_retrieval/law"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.law")}
                leftIcon={<GavelIcon color="primary" />}
            />
            <MenuItemLink
                to="info_retrieval/news"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.info_retrieval")}
                leftIcon={<NewspaperIcon color="primary" />}
            />
        </>
    );
};

export default InfoRetrievalMenuItems;
