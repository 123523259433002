import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import { convertTimestampToString } from "@src/utils/date";

// jsonExport doc - http://kaue.github.io/jsonexport/
// RA exporter - https://marmelab.com/react-admin/List.html#exporter
export const crmDealExporter = async (deals: any[], fetchRelatedRecords: any) => {
    const clients = await fetchRelatedRecords(deals, "client_id", "crm/client");
    let idx = 0;
    const dealsForExport = deals.map(deal => {
        const { client_id, deal_title, deal_description, deal_status, budget, create_at } = deal;
        return {
            idx: ++idx,
            client_name: clients[client_id].client_name,
            client_type: clients[client_id].client_type.code_name,
            deal_title,
            deal_description,
            deal_status: deal_status.code_name,
            budget,
            create_at: convertTimestampToString(create_at),
        };
    });

    jsonExport(
        dealsForExport,
        {
            headers: [
                "idx",
                "client_name",
                "client_type",
                "deal_title",
                "deal_description",
                "deal_status",
                "budget",
                "create_at",
            ], // order fields in the export
            rename: ["번호", "거래처명", "거래처 유형", "거래명", "거래내용", "거래상태", "예산", "생성일"],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "거래목록"); // download as 'deals.csv` file
        },
    );
};

export const crmClientExporter = async (clients: any[]) => {
    let idx = 0;
    const clientsForExport = clients.map(client => {
        const { client_name, client_type, address, address_detail, create_at } = client;
        return {
            idx: ++idx,
            client_name,
            client_type: client_type.code_name,
            address,
            address_detail,
            create_at: convertTimestampToString(create_at),
        };
    });

    jsonExport(
        clientsForExport,
        {
            headers: ["idx", "client_name", "client_type", "address", "address_detail", "create_at"], // order fields in the export
            rename: ["번호", "거래처명", "거래처 유형", "주소", "상세주소", "생성일"],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "거래처목록"); // download as 'deals.csv` file
        },
    );
};

export const crmContactExporter = async (contacts: any[], fetchRelatedRecords: any) => {
    // const clients = await fetchRelatedRecords(contacts, "client_id", "crm/client");
    // TODO Client값이 꼬여서 ㅠㅠ 나중에 한 번 확인해 볼것
    let idx = 0;
    const contactsForExport = contacts.map(contact => {
        const { client_id, contact_name, job_title, email, tel_number, create_at } = contact;
        return {
            idx: ++idx,
            contact_name,
            job_title,
            email,
            tel_number,
            // client_name: clients[client_id].client_name,
            // client_type: clients[client_id].client_type.code_name,
            create_at: convertTimestampToString(create_at),
        };
    });

    jsonExport(
        contactsForExport,
        {
            headers: [
                "idx",
                "contact_name",
                "job_title",
                "email",
                "tel_number",
                // "client_name",
                // "client_type",
                "create_at",
            ], // order fields in the export
            // rename: ["번호", "연락처명", "직급/직함", "이메일", "전화번호", "거래처명", "거래처유형", "생성일"],
            rename: ["번호", "연락처명", "직급/직함", "이메일", "전화번호", "생성일"],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "연락처목록"); // download as 'deals.csv` file
        },
    );
};

export const taskExporter = async (tasks: any[]) => {
    let idx = 0;
    const tasksForExport = tasks.map(task => {
        const {
            inbound_at,
            inbound_channel_category_1,
            inbound_channel_category_2,
            request_title,
            customer_type,
            customer_inbound_count,
            customer_name,
            request_category_1,
            request_category_2,
            request_category_3,
            response_department,
            last_response_userid,
            response_complete_at,
            response_time_to_complete,
            create_at,
            create_userid,
        } = task;
        return {
            idx: ++idx,
            inbound_at: convertTimestampToString(inbound_at),
            inbound_channel_category_1: inbound_channel_category_1.code_name,
            inbound_channel_category_2: inbound_channel_category_2.code_name,
            request_title,
            customer_type: customer_type.code_name,
            customer_inbound_count: customer_inbound_count.code_name,
            customer_name,
            request_category_1: request_category_1.code_name,
            request_category_2: request_category_2.code_name,
            request_category_3: request_category_3.code_name,
            response_department: "두레이 조직도 연계후 수정 예정(wonder)",
            last_response_userid,
            response_complete_at: convertTimestampToString(response_complete_at),
            response_time_to_complete,
            create_at: convertTimestampToString(create_at),
            create_userid,
        };
    });

    jsonExport(
        tasksForExport,
        {
            headers: [
                "idx",
                "inbound_at",
                "inbound_channel_category_1",
                "inbound_channel_category_2",
                "request_title",
                "customer_type",
                "customer_inbound_count",
                "customer_name",
                "request_category_1",
                "request_category_2",
                "request_category_3",
                "response_department",
                "last_response_userid",
                "response_complete_at",
                "response_time_to_complete",
                "create_at",
                "create_userid",
            ], // order fields in the export
            rename: [
                "idx",
                "인바운드일자",
                "인바운드인입경로",
                "인바운드인입채널",
                "문의제목",
                "고객유형",
                "고객인입횟수",
                "고객이름",
                "문의대분류",
                "문의중분류",
                "문의소분류",
                "응대담당부서",
                "응대담당부서 담당자ID",
                "응대완료일자",
                "응대완료까지걸린시간(초)",
                "등록일자",
                "등록자ID",
            ],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "TASK내역"); // download as 'deals.csv` file
        },
    );
};
