import React, { useState, useEffect, ChangeEvent } from "react";
import { useDataProvider, useGetIdentity, useResourceContext, useRecordContext } from "react-admin";
import { TextField, MenuItem, Box } from "@mui/material";

export const DeptSelector = ({ dept, setDept, sx }: any) => {
    const record = useRecordContext();
    const resouce = useResourceContext();
    const dataProvider = useDataProvider();
    const [deptData, setDeptData] = useState<any[]>([]);
    const { identity } = useGetIdentity();

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: "MACCO_DEPT" },
            });
            setDeptData(
                data.map(({ id, parent_code, code, code_name, code_description, code_order }: any) => {
                    return {
                        id,
                        parent_code,
                        code,
                        code_name,
                        code_description,
                        code_order,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    if (deptData.length === 0) return null;

    return (
        <TextField
            required
            select
            value={dept}
            onChange={async (event: ChangeEvent<{ value: unknown }>) => {
                setDept(event.target.value as string);
                dataProvider.update(resouce, {
                    id: record?.id,
                    data: {
                        taskInput: {
                            response_department: "MACCO_DEPT." + event.target.value,
                            task_status: "TASK_STATUS.TASK_ST_01",
                            update_userid: `${identity?.id}(${identity?.fullName})`,
                        },
                    },
                    previousData: record,
                });
            }}
            margin="none"
            size="small"
            sx={{
                minWidth: "160px",
                ...sx,
            }}
        >
            <Box width={10} height={10} display="inline-block" borderRadius={5} component="span" />
            {deptData.map((item: any) => (
                <MenuItem key={item.id} value={item.code}>
                    {item.code_name}
                </MenuItem>
            ))}
        </TextField>
    );
};
