import * as React from "react";
import { useTranslate } from "react-admin";
import { Box, Theme, ListItemIcon, MenuItem, Typography } from "@mui/material";
import WebIconIcon from "@mui/icons-material/Web";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

const PaperMenuItems = ({ dense }: { dense: boolean }) => {
    const translate = useTranslate();

    return (
        <>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.mknews.kr", "_blank");
                }}
            >
                {/* <ListItemIcon>
                    <LanguageOutlinedIcon color="primary" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/mk.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_mk`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.maeilmarketing.com", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/maeil.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_maeil`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.nexteconomy.co.kr/", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/next.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_next`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.ntimes.co.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/net.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_net`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.sisaenews.com", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/sisae.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_sisae`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.yutongdaily.com", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/yutong.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_yutong`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://www.fnnews.com", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/fn.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_fn`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.kongje.or.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/kongje.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.paper_kongje`)}
                </Typography>
            </MenuItem>
        </>
    );
};

export default PaperMenuItems;
