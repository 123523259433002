import * as React from "react";
import { useState, useContext } from "react";
import {
    Identifier,
    useListContext,
    DataProviderContext,
    useGetIdentity,
    useDeepCompareEffect,
    useListFilterContext,
    useTranslate,
} from "react-admin";
import { Box } from "@mui/material";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { Task } from "./types";
import { TaskCard } from "./TaskCard";
import { CardColumn } from "@src/components/dnd/CardColumn";
import { CommonCodeDesc } from "@src/types";

export interface RecordMap {
    [id: number]: Task;
    [id: string]: Task;
}
interface TaskByColumn {
    [taskStatus: string]: Identifier[];
}

const getInitialTask: (taskStatus: CommonCodeDesc[]) => TaskByColumn = taskStatus => {
    return taskStatus.reduce((obj, { code: status }) => ({ ...obj, [status]: [] }), {});
};

const getTaskByColumn = (taskStatus: CommonCodeDesc[], data: Task[]): TaskByColumn => {
    // group tasks by column
    const columns = data.reduce(
        (acc, record) => {
            acc[record.task_status.code].push(record);
            return acc;
        },
        taskStatus.reduce((obj, { code: status }) => ({ ...obj, [status]: [] }), {} as any),
    );
    // order each column by index
    taskStatus.forEach(({ code: status }) => {
        columns[status] = columns[status]
            .sort((recordA: Task, recordB: Task) => recordA.task_status_order - recordB.task_status_order)
            .map((task: Task) => task.id);
    });
    return columns;
};

const indexById = (data: Task[]): RecordMap => data.reduce((obj, record) => ({ ...obj, [record.id]: record }), {});

export const TaskCardListContent = ({
    taskStatus,
    setShowInfo,
}: {
    taskStatus: CommonCodeDesc[];
    setShowInfo: any;
}) => {
    // const resource = useResourceContext();
    // const [{ perPage }, { setPerPage }] = useListParams({ resource });
    const { data: unorderedTask, isLoading, refetch, sort, setSort, perPage, setPerPage } = useListContext<Task>();
    const { filterValues, setFilters, displayedFilters } = useListFilterContext();
    const [data, setData] = useState<RecordMap>(isLoading ? {} : indexById(unorderedTask));
    const [tasks, setTask] = useState<TaskByColumn>(
        isLoading ? getInitialTask(taskStatus) : getTaskByColumn(taskStatus, unorderedTask),
    );
    // we use the raw dataProvider to avoid too many updates to the Redux store after updates (which would create junk)
    const dataProvider = useContext(DataProviderContext);
    const { identity } = useGetIdentity();
    // update tasks by columns when the dataProvider response updates
    useDeepCompareEffect(() => {
        if (isLoading) return;
        if (filterValues && filterValues.task_stash) {
            setFilters({ ...filterValues, task_stash: false }, displayedFilters);
        }
        if (perPage !== 500) {
            setPerPage(500);
        }
        if (sort.field === "id") {
            setSort({
                field: "task_status_order",
                order: "ASC",
            });
        }
        const notStashedTask = unorderedTask.filter(item => item.task_stash == false);
        setTask(getTaskByColumn(taskStatus, notStashedTask));
        setData(indexById(notStashedTask));
    }, [taskStatus, unorderedTask, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
    if (isLoading || perPage !== 500 || filterValues.task_stash) return null;

    const onDragEnd: OnDragEndResponder = async result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // moving task inside the same column

            const column = Array.from(tasks[source.droppableId]); // [4, 7, 23, 5] array of ids
            const sourceTask = data[column[source.index]];
            const destinationTask = data[column[destination.index]];

            // update local state
            // remove source task from column
            column.splice(source.index, 1);
            // read source task at destination
            column.splice(destination.index, 0, draggableId);
            setTask({
                ...tasks,
                [source.droppableId]: column,
            });

            // update backend
            // Fetch all the tasks in this task_status (because the list may be filtered, but we need to update even non-filtered tasks)
            const { data: columnTask } = await dataProvider.getList("task", {
                sort: { field: "task_status_order", order: "ASC" },
                pagination: { page: 1, perPage: 100 },
                filter: { task_status: source.droppableId },
            });

            if (source.index > destination.index) {
                // task moved up, eg
                // dest   src
                //  <------
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all tasks between destination.index and source.index, increase the index
                    ...columnTask
                        .filter(
                            task =>
                                task.task_status_order >= destinationTask.task_status_order &&
                                task.task_status_order < sourceTask.task_status_order,
                        )
                        .map(task =>
                            dataProvider.update("task", {
                                id: task.id,
                                data: {
                                    taskInput: {
                                        id: task.id,
                                        task_status_order: task.task_status_order + 1,
                                        update_userid: `${identity?.id}(${identity?.fullName})`,
                                    },
                                },
                                previousData: task,
                            }),
                        ),
                    // for the task that was moved, update its index
                    dataProvider.update("task", {
                        id: sourceTask.id,
                        data: {
                            taskInput: {
                                id: sourceTask.id,
                                task_status_order: destinationTask.task_status_order,
                                update_userid: `${identity?.id}(${identity?.fullName})`,
                            },
                        },
                        previousData: sourceTask,
                    }),
                ]);

                refetch();
            } else {
                // task moved down, e.g
                // src   dest
                //  ------>
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all tasks between source.index and destination.index, decrease the index
                    ...columnTask
                        .filter(
                            task =>
                                task.task_status_order <= destinationTask.task_status_order &&
                                task.task_status_order > sourceTask.task_status_order,
                        )
                        .map(task =>
                            dataProvider.update("task", {
                                id: task.id,
                                data: {
                                    taskInput: {
                                        id: task.id,
                                        task_status_order: task.task_status_order - 1,
                                        update_userid: `${identity?.id}(${identity?.fullName})`,
                                    },
                                },
                                previousData: task,
                            }),
                        ),
                    // for the task that was moved, update its index
                    dataProvider.update("task", {
                        id: sourceTask.id,
                        data: {
                            taskInput: {
                                id: sourceTask.id,
                                task_status_order: destinationTask.task_status_order,
                                update_userid: `${identity?.id}(${identity?.fullName})`,
                            },
                        },
                        previousData: sourceTask,
                    }),
                ]);

                refetch();
            }
        } else {
            // moving task across columns

            const sourceColumn = Array.from(tasks[source.droppableId]); // [4, 7, 23, 5] array of ids
            const destinationColumn = Array.from(tasks[destination.droppableId]); // [4, 7, 23, 5] arrays of ids
            const sourceTask = data[sourceColumn[source.index]];
            const destinationTask = data[destinationColumn[destination.index]]; // may be undefined if dropping at the end of a column

            // update local state
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setTask({
                ...tasks,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            // update backend
            // 신규 문의로 이동 시 부서 설정 초기화
            if (destination.droppableId === "TASK_STATUS.TASK_ST_01") {
                dataProvider.update("task", {
                    id: sourceTask?.id,
                    data: {
                        taskInput: {
                            response_department: "",
                            task_status: "TASK_STATUS.TASK_ST_01",
                            update_userid: `${identity?.id}(${identity?.fullName})`,
                        },
                    },
                    previousData: sourceTask,
                });
            }
            // Fetch all the tasks in both task_status (because the list may be filtered, but we need to update even non-filtered tasks)
            const [{ data: sourceTasks }, { data: destinationTasks }] = await Promise.all([
                dataProvider.getList("task", {
                    sort: { field: "task_status_order", order: "ASC" },
                    pagination: { page: 1, perPage: 100 },
                    filter: { task_status: source.droppableId },
                }),
                dataProvider.getList("task", {
                    sort: { field: "task_status_order", order: "ASC" },
                    pagination: { page: 1, perPage: 100 },
                    filter: { task_status: destination.droppableId },
                }),
            ]);

            await Promise.all([
                // decrease index on the tasks after the source index in the source columns
                ...sourceTasks
                    .filter(task => task.task_status_order > sourceTask.task_status_order)
                    .map(task =>
                        dataProvider.update("task", {
                            id: task.id,
                            data: {
                                taskInput: {
                                    id: task.id,
                                    task_status_order: task.task_status_order - 1,
                                    update_userid: `${identity?.id}(${identity?.fullName})`,
                                },
                            },
                            previousData: task,
                        }),
                    ),
                // increase index on the tasks after the destination index in the destination columns
                ...destinationTasks
                    .filter(task =>
                        destinationTask ? task.task_status_order >= destinationTask.task_status_order : false,
                    )
                    .map(task =>
                        dataProvider.update("task", {
                            id: task.id,
                            data: {
                                taskInput: {
                                    id: task.id,
                                    task_status_order: task.task_status_order + 1,
                                    update_userid: `${identity?.id}(${identity?.fullName})`,
                                },
                            },
                            previousData: task,
                        }),
                    ),
                // change the dragged task to take the destination index and column
                dataProvider.update("task", {
                    id: sourceTask.id,
                    data: {
                        taskInput: {
                            id: sourceTask.id,
                            task_status_order: destinationTask
                                ? destinationTask.task_status_order
                                : destinationTasks.length !== 0
                                ? destinationTasks.pop().task_status_order + 1
                                : 0,
                            task_status: "TASK_STATUS." + destination.droppableId,
                            update_userid: `${identity?.id}(${identity?.fullName})`,
                        },
                    },
                    previousData: sourceTask,
                }),
            ]);

            refetch();
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {taskStatus.map(({ code: status, code_description }) => (
                    <CardColumn
                        title={code_description}
                        status={status}
                        ids={tasks[status]}
                        data={data}
                        key={status}
                        card={<TaskCard />}
                        setShowInfo={setShowInfo}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
};
