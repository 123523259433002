import { Card, Divider, Tab, Tabs, Theme, useMediaQuery } from "@mui/material";
import {
    Datagrid,
    List,
    TextField,
    FunctionField,
    Pagination,
    useListContext,
    ListContextProvider,
    useTranslate,
    RaRecord,
    useDataProvider,
    useList,
    SimpleList,
    Title,
    FilterForm,
    ListToolbar,
    ListBase,
} from "react-admin";
import { format } from "date-fns";
import { isValidDate } from "@src/utils/date";
import { Fragment, useCallback, useEffect, useState } from "react";
// import MobileNewsGrid from "./MobileNewsGrid";
import { SearchInput } from "react-admin";
import { ExternalLinkField } from "@src/components/fields";
import { BookmarkField } from "@src/components/fields/BookmarkField";

const NewsFields = [
    <ExternalLinkField label="common.subject" sortable={false} source="title" textAlign="center" />,
    <TextField label="news.originallink" source="originallink" sortable={false} textAlign="center" />,
    <FunctionField
        label="common.date"
        sortable={false}
        textAlign="center"
        render={(record: any) => {
            let date = new Date(record.pubDate);
            if (!isValidDate(date)) {
                // blog 날짜 응답 format이 "yyyyMMdd"여서 파싱 작업이 필요
                if (/^(\d){8}$/.test(record.pubDate)) {
                    const y = record.pubDate.substr(0, 4);
                    const m = record.pubDate.substr(4, 2) - 1;
                    const d = record.pubDate.substr(6, 2);
                    date = new Date(y, m, d);
                }
            }
            return format(date, "yyyy-MM-dd");
        }}
    />,
];

const SearchFields = [
    <TextField label="테스트필드1" source="field1" sortable={false} textAlign="center" />,
    <TextField label="테스트필드2" source="field2" sortable={false} textAlign="center" />,
    <TextField label="테스트필드3" source="field3" sortable={false} textAlign="center" />,
];

const LawFields = [
    <TextField label="law.law.col1" source="col1" sortable={false} textAlign="center" />,
    <TextField label="law.law.col2" source="col2" sortable={false} textAlign="center" />,
    <ExternalLinkField label="law.law.title" sortable={false} source="title" textAlign="center" />,
];

const filters = [<SearchInput source="search" alwaysOn />];

export const SearchList = () => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [showData, setShowData] = useState<any>({});
    const [tabType, setTabType] = useState<string>("doc");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(100);
    // const [serachKeyword, setSearchKeyword] = useState("");
    const listContext = useList({
        data: showData[tabType],
        page,
        perPage,
        // filter: {
        //     search: "",
        // },
    });
    // const { setFilters, filterValues, displayedFilters } = listContext;
    // console.log(listContext);

    useEffect(() => {
        let resource = "";
        let type = "";
        if (tabType === "doc" || tabType === "approval" || tabType === "email") {
            resource = "info_retrieval/search";
            type = tabType;
        } else if (tabType === "law") {
            resource = "info_retrieval/law";
            type = "law";
        } else if (tabType === "news") {
            resource = "info_retrieval/news";
            type = "news";
        }

        const getData = async () => {
            const response = await dataProvider.getList(resource, {
                sort: { field: "id", order: "ASC" },
                pagination: { page, perPage },
                filter: { type },
            });

            setShowData({
                ...showData,
                [tabType]: response.data,
            });
            setTotal(response.total as number);
        };
        getData();
    }, [tabType, page, perPage]);

    const handleChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
        setTabType(value);
        // setFilters &&
        //     setFilters(
        //         { ...filterValues },
        //         displayedFilters,
        //         false, // no debounce, we want the filter to fire immediately
        //     );
    }, []);

    // Tab 형식으로 문서, 전자결재, 이메일, 법률, 인터넷 배치
    const tabs = [
        { id: "doc", name: translate("search.doc") },
        { id: "approval", name: translate("dashboard.approval") },
        { id: "email", name: translate("dashboard.email") },
        { id: "law", name: translate("info_retrieval.types.law") },
        { id: "news", name: translate("info_retrieval.types.news") },
    ];

    if (!showData[tabType]) {
        return null;
    }

    // filters={filters} />
    return (
        <ListContextProvider key={tabType} value={{ ...listContext, data: showData[tabType] }}>
            <Title title={translate("title.info_retrieval")} />
            <ListToolbar /*filters={filters}*/ />
            <Card>
                <Tabs variant="fullWidth" centered value={tabType} indicatorColor="primary" onChange={handleChange}>
                    {tabs.map(choice => (
                        <Tab key={choice.id} label={choice.name} value={choice.id} />
                    ))}
                </Tabs>
                <Divider />
                {isXSmall ? null : ( //<MobileNewsGrid type={filterValues.type} />
                    <Datagrid rowClick="expand" bulkActionButtons={false} /*expand={SearchPanel}*/>
                        <TextField label="common.index" source="id" sortable={false} textAlign="center" />
                        {tabType === "doc" && SearchFields}
                        {tabType === "approval" && SearchFields}
                        {tabType === "email" && SearchFields}
                        {tabType === "law" && LawFields}
                        {tabType === "news" && NewsFields}
                    </Datagrid>
                )}
            </Card>
            <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} total={total} />
        </ListContextProvider>
    );
};
