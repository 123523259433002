import { useTheme } from "react-admin";

export const ChartTooltip = ({ id, color, content }: any) => {
    const [theme] = useTheme();
    return (
        <div
            style={{
                fontSize: 12,
                padding: 12,
                color: theme?.palette?.mode == "dark" ? "#efefef" : "#41444a",
                border: theme?.palette?.mode == "dark" ? "1px solid #191919" : "1px solid #efefe",
                background: theme?.palette?.mode == "dark" ? "#191919" : "#fff",
            }}
        >
            <span style={{ display: "inline-block", width: "10px", height: "10px", background: color }}></span>
            {` ${id} `}
            {content}
        </div>
    );
};
