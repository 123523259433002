import { CreateUpdateFields, NestedCommonCodeFields } from "./fieldsCommon";

const CrmContact = `
    id
    client_id
    contact_name
    email
    tel_number
    job_title
    thumbnail
    individual_tel_number
    fax_tel_number
    bigo_note
    hasNote
    ${CreateUpdateFields}
`;

const CrmContactNote = `
    id
    contact_id
    note
    ${CreateUpdateFields}
`;

const CrmClient = `
    id
    client_type ${NestedCommonCodeFields}
    client_name
    address
    address_detail
    tel_number
    website
    bigo_note
    thumbnail
    ${CreateUpdateFields}
`;

const CrmDeal = `
    id
    client_id
    deal_title
    deal_description
    deal_status ${NestedCommonCodeFields}
    deal_status_order
    deal_stash
    budget
    ${CreateUpdateFields}
`;

const CrmDealNote = `
    id
    deal_id
    note
    ${CreateUpdateFields}
`;

export const fieldsCrm = { CrmContact, CrmContactNote, CrmClient, CrmDeal, CrmDealNote };
