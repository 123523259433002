import i18nProvider from "./configuration/setLocales";
import { Admin, ListGuesser, ShowGuesser, EditGuesser, Resource, CustomRoutes } from "react-admin";
import { useAuthProvider } from "./authProvider";
import { Dashboard } from "./pages/dashboard";
import { Layout } from "./layout";
import { lightTheme } from "./layout/themes";
import { getCrmContactResourceProps, getCrmClientResourceProps, getCrmDealResourceProps } from "./pages/crm";
import { getTaskResourceProps } from "./pages/task";
import { Route } from "react-router";
// import { searchResourceProps } from "./pages/info_retrieval/search";
import { newsResourceProps } from "./pages/info_retrieval/news";
import { lawResourceProps } from "./pages/info_retrieval/law";
import { bookmarkResourceProps } from "./pages/info_retrieval/bookmark";
import { AccountManager } from "./pages/system/account_mgmt/AccountManager";
import { useDataProvider } from "./dataProvider/dataProvider";
import NotFound from "./NotFound";
import Login from "./Login";
import { SearchList } from "./pages/info_retrieval/search/SearchList";

const fetchResourcesAndCustomRoutes = (permissions: any) => {
    let routes: JSX.Element[] = [<Route key={0} path="search" element={<SearchList />} />];
    let resources: JSX.Element[] = [
        <Resource name="CommonCode" />,
        // <Resource name="info_retrieval/search" {...searchResourceProps} />,
        <Resource name="info_retrieval/news" {...newsResourceProps} />,
        <Resource name="info_retrieval/law" {...lawResourceProps} />,
        <Resource name="info_retrieval/bookmark" {...bookmarkResourceProps} />,
    ];

    if (permissions) {
        for (const prop in permissions) {
            let allowedResources: JSX.Element[] = [];
            let allowedCustomRoutes: JSX.Element[] = [];
            const permission = permissions[prop];
            switch (prop) {
                case "crm":
                    allowedResources = [
                        <Resource name="crm/deal" {...getCrmDealResourceProps(permission)} />,
                        <Resource name="crm/contact" {...getCrmContactResourceProps(permission)} />,
                        <Resource name="crm/client" {...getCrmClientResourceProps(permission)} />,
                        <Resource name="crm/contact_note" />,
                        <Resource name="crm/deal_note" />,
                    ];
                    break;
                case "task":
                    allowedResources = [
                        <Resource name="task" {...getTaskResourceProps(permission)} />,
                        <Resource name="task/note" />,
                    ];
                    break;
                case "account_mgmt":
                    allowedCustomRoutes = [
                        <Route
                            key={routes.length + allowedCustomRoutes.length}
                            path="system/account_manager"
                            element={<AccountManager />}
                        />,
                    ];
                    break;
            }
            resources = [...resources, ...allowedResources];
            routes = [...routes, ...allowedCustomRoutes];
        }
    }

    return [...resources, <CustomRoutes>{routes}</CustomRoutes>];
};

const App = () => {
    const authProvider = useAuthProvider();
    const dataProvider = useDataProvider();
    return (
        <Admin
            requireAuth
            loginPage={Login}
            catchAll={NotFound}
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
        >
            {fetchResourcesAndCustomRoutes}
        </Admin>
    );
};

export default App;
