import React, { useRef } from "react";
import { useState } from "react";
import {
    useRecordContext,
    useCreate,
    useNotify,
    useGetIdentity,
    useResourceContext,
    useTranslate,
    useListContext,
    Form,
} from "react-admin";
import { Button, Box } from "@mui/material";

import { RichTextInputWithImageAttachment } from "@components/inputs/RichTextInputWithImageAttachment";
import { fontFamily } from "@src/layout/themes";
import { foreignKeyMapping } from "./Note";
import { NoteReferences } from "./NotesIterator";

export const NewNote = ({ reference }: { reference: NoteReferences }) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const [text, setText] = useState("");
    const [create, { isLoading }] = useCreate();
    const { refetch } = useListContext();
    const notify = useNotify();
    const [editor, setEditor] = useState<any>(null);
    const { identity } = useGetIdentity();
    const translate = useTranslate();
    if (!record || !identity) return null;
    const handleSubmit = (data: any) => {
        create(
            resource,
            {
                data: {
                    noteInput: {
                        [foreignKeyMapping[reference]]: data.id,
                        create_userid: `${identity?.id}(${identity?.fullName})`,
                        note: text,
                    },
                },
            },
            {
                onSuccess: () => {
                    if (editor && editor.commands) {
                        editor.commands.clearContent();
                        editor.commands.setFontFamily(fontFamily);
                    }
                    setText("");
                    notify("ra.notification.created");
                    refetch();
                },
            },
        );
        return false;
    };

    const handleChange = (value: any) => setText(value);

    return (
        <Box mt={4} mb={1}>
            <Form onSubmit={handleSubmit}>
                <RichTextInputWithImageAttachment
                    label={false}
                    name="note"
                    source="note"
                    defaultValue=""
                    setEditor={setEditor}
                    handleChange={handleChange}
                />
                <Box display="flex" justifyContent="end" mt={1}>
                    <Button type="submit" variant="contained" color="primary" disabled={!text || isLoading}>
                        {translate("crm.addNote")}
                    </Button>
                </Box>
            </Form>
        </Box>
    );
};
