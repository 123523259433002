import * as React from "react";
import {
    List,
    TopToolbar,
    ExportButton,
    CreateButton,
    Pagination,
    useGetIdentity,
    useTranslate,
    useGetList,
    SelectInput,
    FilterButton,
    TextInput,
    usePermissions,
} from "react-admin";
import { GridList } from "./GridList";
import { crmClientExporter } from "@src/utils/exporters";

export const ClientList = () => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    // const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    const { data, isLoading } = useGetList("CommonCode", {
        sort: { field: "code_order", order: "ASC" },
        filter: { parent_code: "CRM_CLIENT_TYPE" },
    });
    if (isLoading || !data) return null;
    // if (isLoadingForPemission || !permissions.crm?.read) return null;
    const clientTypeChoices = data.map(item => {
        return {
            id: item.parent_code + "." + item.code,
            name: item.code_name,
        };
    });

    const defaultFilters = [
        <SelectInput label={translate("crm.clientType")} source="client_type" choices={clientTypeChoices} />,
        <TextInput label={translate("crm.clientName")} source="client_name" />,
    ];

    return identity ? (
        <List
            actions={<ClientListActions />}
            pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
            perPage={25}
            sort={{ field: "client_name", order: "ASC" }}
            component="div"
            title={translate("title.crm_client")}
            filters={defaultFilters}
            exporter={crmClientExporter}
            empty={false}
        >
            <GridList />
        </List>
    ) : null;
};

const ClientListActions = () => {
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    if (isLoadingForPemission) return null;
    return (
        <TopToolbar>
            <FilterButton />
            <ExportButton />
            {/* {(true || permissions.crm?.create) && ( */}
            <CreateButton variant="contained" label="crm.addClient" sx={{ marginLeft: 2 }} />
            {/* )} */}
        </TopToolbar>
    );
};
