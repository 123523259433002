import React, { useEffect } from "react";
import { useState } from "react";
import {
    useResourceContext,
    useDelete,
    useUpdate,
    useNotify,
    useTranslate,
    useGetIdentity,
    useTheme,
    FunctionField,
    IconButtonWithTooltip,
    RichTextField,
    Form,
    RecordContextProvider,
    usePermissions,
} from "react-admin";
import { Button, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";

import { format } from "date-fns";
import { RichTextInputWithImageAttachment } from "@components/inputs/RichTextInputWithImageAttachment";
import { NoteReferences } from "./NotesIterator";
import { getUserName } from "@src/utils/utils";

export const foreignKeyMapping = {
    "crm/contact": "contact_id",
    "crm/deal": "deal_id",
    task: "task_id",
};

export const permissionMapping = {
    "crm/contact": "crm",
    "crm/deal": "crm",
    task: "task",
};

export const Note = ({
    record,
    refetch,
    reference,
}: {
    record: any;
    statusTooltip?: string;
    refetch: any;
    reference: NoteReferences;
}) => {
    // const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    const [isHover, setHover] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [noteText, setNoteText] = useState<string>(record.note);
    const [editor, setEditor] = useState<any>(null);
    const resource = useResourceContext();
    const notify = useNotify();
    const [update, { isLoading }] = useUpdate();
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    const [theme] = useTheme();

    const [deleteNote] = useDelete(
        resource,
        {
            id: record.id,
            previousData: record,
        },
        {
            mutationMode: "pessimistic",
            onSuccess: () => {
                notify("ra.notification.deleted", {
                    type: "info",
                    undoable: false,
                });
            },
        },
    );

    useEffect(() => {
        if (noteText != record.note) {
            setNoteText(record.note);
        }
    }, [record.note]);

    const handleChange = (value: any) => setNoteText(value);

    const handleDelete = () => {
        deleteNote();
    };

    const handleEnterEditMode = () => {
        setEditing(true);
    };

    const handleCancelEdit = () => {
        setEditing(false);
        setNoteText(record.note);
        setHover(false);
    };

    const handleNoteUpdate = (data: any) => {
        update(
            resource,
            {
                id: record.id,
                data: {
                    noteInput: {
                        [foreignKeyMapping[reference]]: data.id,
                        note: noteText,
                        update_userid: `${identity?.id}(${identity?.fullName})`,
                    },
                },
            },
            {
                onSuccess: ({ note }: { note: string }) => {
                    setEditing(false);
                    setNoteText(note);
                    setHover(false);
                    refetch();
                },
            },
        );
    };

    if (!identity) return null;
    // if (isLoadingForPemission) return null;

    return (
        <Box mb={2} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Box mb={1} color="text.secondary">
                {`${getUserName(record.create_userid)} `}
                <FunctionField
                    record={record}
                    source="create_at"
                    render={({ create_at }: { create_at: string }) => {
                        return format(new Date(Number(create_at)), "yyyy-MM-dd HH:mm:ss");
                    }}
                />{" "}
            </Box>
            {isEditing ? (
                <Form onSubmit={handleNoteUpdate}>
                    <Box
                        sx={{
                            bgcolor: theme?.palette?.mode === "dark" ? "#525252" : "#edf3f0",
                            padding: "5px 10px",
                            borderRadius: "10px",
                        }}
                    >
                        <RichTextInputWithImageAttachment
                            label={false}
                            name="note"
                            source="note"
                            defaultValue={noteText}
                            setEditor={setEditor}
                            handleChange={handleChange}
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                        <Button sx={{ mr: 1 }} onClick={handleCancelEdit} color="primary">
                            {translate("ra.action.cancel")}
                        </Button>
                        <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
                            {translate("crm.editNote")}
                        </Button>
                    </Box>
                </Form>
            ) : (
                <Box
                    sx={{
                        bgcolor: theme?.palette?.mode === "dark" ? "#525252" : "#edf3f0",
                        padding: "0 1em",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "stretch",
                        marginBottom: 1,
                    }}
                >
                    <Box flex={1}>
                        <RecordContextProvider value={record}>
                            <RichTextField source="note" defaultValue={noteText} fullWidth />
                        </RecordContextProvider>
                    </Box>
                    <Box
                        sx={{
                            marginLeft: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            visibility: isHover ? "visible" : "hidden",
                        }}
                    >
                        {/* {permissions[permissionMapping[reference]]?.update && ( */}
                        <IconButtonWithTooltip
                            label={translate("crm.editNote")}
                            size="small"
                            onClick={handleEnterEditMode}
                        >
                            <EditIcon />
                        </IconButtonWithTooltip>
                        {/* )} */}
                        {/* {permissions[permissionMapping[reference]]?.delete && ( */}
                        <IconButtonWithTooltip label={translate("crm.deleteNote")} size="small" onClick={handleDelete}>
                            <TrashIcon />
                        </IconButtonWithTooltip>
                        {/* )} */}
                    </Box>
                </Box>
            )}
        </Box>
    );
};
