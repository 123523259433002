import * as React from "react";
import { useState } from "react";
import { Paper, Typography, Link as MuiLink, ListItemText, useMediaQuery, Theme } from "@mui/material";
import { Box } from "@mui/material";
import ContactsIcon from "@mui/icons-material/AccountCircle";
import DealIcon from "@mui/icons-material/MonetizationOn";
import {
    SelectField,
    ReferenceManyField,
    useCreatePath,
    useRecordContext,
    useListContext,
    useTranslate,
    useGetList,
} from "react-admin";
import { Link } from "react-router-dom";
import { ClientAvatar } from "./ClientAvatar";
import { CrmClient } from "../types";

export const ClientCard = (props: { record?: CrmClient }) => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const [elevation, setElevation] = useState(1);
    const translate = useTranslate();
    const createPath = useCreatePath();
    const record = useRecordContext<CrmClient>(props);
    const { data, isLoading } = useGetList("CommonCode", {
        sort: { field: "code_order", order: "ASC" },
        filter: { parent_code: "CRM_CLIENT_TYPE" },
    });
    if (isLoading || !data) return null;
    const clientTypeChoices = data.map(item => {
        return {
            id: item.code,
            name: item.code_name,
        };
    });
    if (!record) return null;
    return (
        <MuiLink
            component={Link}
            to={createPath({
                resource: "crm/client",
                id: record.id,
                type: "show",
            })}
            underline="none"
            onMouseEnter={() => setElevation(3)}
            onMouseLeave={() => setElevation(1)}
        >
            <Paper
                sx={{
                    height: 200,
                    width: isDesktop ? 195 : 130,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: isDesktop ? "1em" : "0 0.5em 2em",
                }}
                elevation={elevation}
            >
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 5 }}>
                    <ClientAvatar sx={{ width: 70, height: 70 }} />
                    <Box textAlign="center" marginTop={1}>
                        <Typography variant="subtitle2">{record.client_name}</Typography>
                        <SelectField color="textSecondary" source="client_type" choices={clientTypeChoices} />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-around" width="100%">
                    <Box display="flex" alignItems="center">
                        <ContactsIcon color="disabled" sx={{ mr: 1 }} />
                        <Box textAlign="center">
                            <Typography variant="caption" color="textSecondary">
                                {translate("common.contacts")}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ mb: -1 }}>
                                <ReferenceManyField
                                    label={translate("common.contacts")}
                                    reference="crm/contact"
                                    target="client_id"
                                >
                                    <RecordsCounter />
                                </ReferenceManyField>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DealIcon color="disabled" sx={{ mr: 1 }} />
                        <Box textAlign="center">
                            <Typography variant="caption" color="textSecondary">
                                {translate("crm.deals")}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ mb: -1 }}>
                                <ReferenceManyField
                                    label={translate("crm.deals")}
                                    reference="crm/deal"
                                    target="client_id"
                                >
                                    <RecordsCounter />
                                </ReferenceManyField>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </MuiLink>
    );
};

const RecordsCounter = () => {
    const { total: counter, isLoading } = useListContext();
    if (isLoading) return null;
    return <ListItemText primary={`${counter}`} />;
};
