import React, { useEffect, useState } from "react";
import InsightsIcon from "@mui/icons-material/Insights";
import { useDataProvider, useTheme, useTranslate } from "react-admin";
import { ChartBox } from "@src/components/charts/ChartBox";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { CircularProgress, Box, Typography } from "@mui/material";
import { LineChart } from "@src/components/charts/LineChart";

const dailyMap: any = {
    //TT_ETC: "그 외 매출",
    //TT_TOP: "상위 5개사",
    TT_SALE2: "후원방문",
    TT_SALE1: "다단계",
    TT_SUM: "합계(다단계+후방)",
    TT_AVG: "평균",
};

export const GuaranteeChartDay = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const setChartData = async () => {
            const { data: apiData } = await dataProvider.callExternalApi("/viewData/viewGuarDaily");
            //console.log("Raw API data:", apiData);
            const adjustedData = Object.keys(dailyMap).map(key => {
                return {
                    id: dailyMap[key],
                    data: apiData
                        .map((item: any) => {
                            const dayData = JSON.parse(item.data);
                            return { x: dayData["STAT_DAY"], y: dayData[key] };
                        })
                        .filter((item: any) => item.y !== 0), // 0인 경우 제외
                };
            });
            console.log("Adjusted data for chart:", adjustedData);
            setData(adjustedData);
        };
        setChartData();
    }, []);

    const tickValues = data[0]?.data
        .map((item: any, index: number) => (index % 3 === 0 ? item.x : null))
        .filter((item: any) => item != null);

    return (
        <div>
            <ChartBox
                chart={
                    data.length > 0 ? (
                        <LineChart
                            data={data} // { date: 날짜데이터, [y축 항목1]: [항목1 데이터값], [y축 항목2]: [항목2 데이터값] ...  }
                            //keys={arrKeys} 그외매출, 상위5개사, 후원, 다단계, 합계, 연평균
                            //colors={["#1b9e77", "#ff7f00",  "#82f0f0", "#d62728", "#80e12a"]} //그외, 상위5개사
                            colors={["#ff7f00", "#0a6eff", "#d62728", "#80e12a"]}
                            axisRight={{
                                format: (v: any) => `${Math.floor(v / 100000000)}억`,
                                tickValues: 8,
                            }}
                            axisBottom={{
                                legendPosition: "middle",
                                legendOffset: 50,
                                tickSize: 0,
                                tickPadding: 12,
                                tickRotation: 40,
                                tickValues: tickValues,
                                format: (date: string) => {
                                    // 1일인 경우만 날짜 표시
                                    // if (date.slice(6, 8) === "01") {
                                    //     return `${date.slice(0, 4)}-${date.slice(4, 6)}`;
                                    // } else {
                                    //     return "";
                                    // }
                                    return `${date.slice(0, 8)}`;
                                },
                            }}
                            tooltip={({ point }: any) => {
                                return (
                                    <ChartTooltip
                                        //id={`${point.data.x.slice(2, 6)}-${point.data.x.slice(6, 7)}: `}
                                        id={` `}
                                        color={point.borderColor}
                                        content={`${Math.floor(+point.data.y / 100000000).toLocaleString()}억`}
                                    />
                                );
                            }}
                            enableGridX={false}
                            enableGridY={true}
                            enableArea={true}
                            lineWidth={2}
                            pointSize={5}
                        />
                    ) : (
                        <CircularProgress />
                    )
                }
            />
        </div>
    );
};
