import * as React from "react";
import { CreateBase, TopToolbar, ListButton, useTranslate, Title, useGetIdentity } from "react-admin";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { ContactInput, ContactInputs } from "./ContactInputs";

export const ContactCreate = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const translate = useTranslate();
    const { identity } = useGetIdentity();

    return (
        <CreateBase
            transform={({
                id,
                contact_name,
                client_id,
                job_title,
                email,
                tel_number,
                thumbnail,
                individual_tel_number,
                fax_tel_number,
                bigo_note,
            }: ContactInput) => ({
                id,
                contactInput: {
                    client_id,
                    contact_name,
                    email,
                    tel_number,
                    job_title,
                    create_userid: `${identity?.id}(${identity?.fullName})`,
                    thumbnail: thumbnail ? thumbnail.rawFile : null,
                    individual_tel_number,
                    fax_tel_number,
                    bigo_note,
                },
            })}
        >
            <Title title={translate("crm.addContact")} />
            <TopToolbar>
                <ListButton />
            </TopToolbar>
            <ContactInputs />
        </CreateBase>
    );
};
