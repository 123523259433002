export const CreateUpdateFields = `
    create_at
    create_userid
    update_at
    update_userid
`;

export const NestedCommonCodeFields = `
    {
        parent_code
        code
        code_name
        code_description
        code_order
    }
`;

const CommonCode = `
    id
    parent_code
    code
    code_name
    code_description
    code_order
`;

const CommonAttachedFile = `
    id
    file_group
    origin_file_name
    origin_file_extension
    stored_file_name
    create_at
    create_userid
`;

export const fieldsCommon = { CommonCode, CommonAttachedFile };
