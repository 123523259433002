import * as React from "react";
import { useTranslate } from "react-admin";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

const AccountMgmtMenuItems = ({ dense }: { dense: boolean }) => {
    const translate = useTranslate();
    const adminURL = "https://dss.macco.or.kr:3030/dss/financial/";

    return (
        <>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open(`${adminURL}selectPageListDailyFinancialMgt.do`, "_blank");
                }}
            >
                <ListItemIcon>
                    <AnalyticsOutlinedIcon color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.accountMgmt_day`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open(`${adminURL}selectPageListWeeklyIncomeMgt.do`, "_blank");
                }}
            >
                <ListItemIcon>
                    <AnalyticsOutlinedIcon color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.accountMgmt_week`)}
                </Typography>
            </MenuItem>
        </>
    );
};

export default AccountMgmtMenuItems;
