import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useDataProvider, useTheme, useTranslate } from "react-admin";
import { CircularProgress, Box, Typography, Card } from "@mui/material";
import { GuaranteeChartDay } from "./GuaranteeChartDay";
import { GuaranteeChartWeek } from "./GuaranteeChartWeek";
import { GuaranteeChartMon } from "./GuaranteeChartMon";
import { GuaranteeChartYear } from "./GuaranteeChartYear";
import { DamboChart } from "./DamboChart";
import { GuaranteeChart } from "./GuaranteeChart";

export const TabChart = () => {
    const translate = useTranslate();
    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box display="flex" alignItems="center" marginBottom="10px" marginLeft="8px" marginTop="15px">
                {/* <Box ml={2} mr={2} display="flex">
                    <BusinessIcon color="disabled" fontSize="large" />
                </Box> */}
                <Typography variant="h6" color="#2f58aa">
                    {translate("dashboard.guarantee_chart")}
                </Typography>
            </Box>
            <Card>
                <Box sx={{ width: "100%", typography: "body1" }}>
                    <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="일" />
                            <Tab label="주" />
                            <Tab label="월" />
                            <Tab label="년" />
                        </Tabs>
                    </Box>
                    {value === 0 && <GuaranteeChartDay />}
                    {value === 1 && <GuaranteeChartWeek />}
                    {value === 2 && <GuaranteeChartMon />}
                    {value === 3 && <GuaranteeChartYear />}
                </Box>
            </Card>
        </div>
    );
};
