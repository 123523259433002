import * as React from "react";
import { Avatar } from "@mui/material";
import { useRecordContext } from "react-admin";
import PersonIcon from "@mui/icons-material/Person";

type ContactAvatarProps = {
    isInput?: boolean;
    watch?: any;
    sx?: Object;
    clientType?: string;
};

export const ContactAvatar = ({ isInput = false, watch, sx = {} }: ContactAvatarProps) => {
    const record = useRecordContext();
    const hasThumbnail = record && record.thumbnail;
    const bgcolor = "none";
    if (isInput) {
        if (watch) {
            let src = "";
            if (watch.src) {
                src = watch.src;
            } else {
                const { base64 } = JSON.parse(watch);
                src = base64;
            }
            return (
                <Avatar
                    src={src}
                    sx={{
                        ...sx,
                        bgcolor,
                        "& img": {
                            objectFit: "contain",
                            background: "white",
                        },
                    }}
                />
            );
        }
    } else if (hasThumbnail) {
        const { base64 } = JSON.parse(record.thumbnail);
        const src = base64;
        return (
            <Avatar
                src={src}
                sx={{
                    ...sx,
                    bgcolor,
                    "& img": { objectFit: "contain", background: "white" },
                }}
            />
        );
    }

    const icon = <PersonIcon />;

    return (
        <Avatar
            sx={{
                ...sx,
                bgcolor,
            }}
        >
            {icon}
        </Avatar>
    );
};
