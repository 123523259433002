const setAuthTokenData = (access_token: string, expires_in: number) => {
    localStorage.setItem("token", access_token);
    setTimeout(() => {
        console.log("setAuthTokenData");
        //updateAuthToken();
    }, expires_in);
};

export const updateAuthToken = async () => {
    const refresh_token = localStorage.getItem("refresh_token");
    if (refresh_token) {
        const response = await fetch(`${process.env.REACT_APP_EXPRESS_SERVER_URI as string}/refresh_token`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ refresh_token }),
        });
        const { tokenData } = await response.json();
        if (tokenData) {
            const { access_token, expires_in } = tokenData;
            setAuthTokenData(access_token, expires_in);
        }
    }
};

export const useAuthProvider = () => ({
    login: () => {
        return Promise.resolve();
    },
    logout: () => {
        localStorage.clear();
        location.href = `https://macco.ncpworkplace.com/authn/logout`;
        return Promise.reject();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        if (!localStorage.getItem("token")) {
            const url = window.location;
            if (!url.search) {
                localStorage.clear();
                location.href = `${process.env.REACT_APP_EXPRESS_SERVER_URI as string}/login`;
            }
            const strUserInfo = decodeURIComponent(url.search).substring("?userInfo=".length);
            if (strUserInfo) {
                const userInfo = JSON.parse(strUserInfo);
                // 차단하려는 사용자 이메일들 설정
                const blockedUserEmails = ["info@macco.or.kr", "shhan@macco.or.kr", "korea@macco.or.kr"];
                // 사용자 이메일 확인 및 차단 리스트에 있는지 체크
                if (blockedUserEmails.includes(userInfo.userEmail)) {
                    localStorage.clear();
                    location.href = `${process.env.REACT_APP_EXPRESS_SERVER_URI as string}/login`;
                    return Promise.reject("Access denied for blocked user.");
                }
                localStorage.setItem("refresh_token", userInfo.refresh_token);
                localStorage.setItem("userEmail", userInfo.userEmail);
                localStorage.setItem("userName", userInfo.userName);
                setAuthTokenData(userInfo.access_token, userInfo.expires_in);

                // url 쿼리스트링 제거
                history.pushState({}, document.title, "/");
                return Promise.resolve();
            } else {
                localStorage.clear();
                location.href = `${process.env.REACT_APP_EXPRESS_SERVER_URI as string}/login`;
            }
        }
        /*
            checkAuth에서 Promise.reject()를 하면 logOut이 다음 콜백으로 호출돼 로그인 페이지로 이동하지 않는 이슈가 발생
            때문에 Promise.reject()인 경우 로그인 페이지로 리다이렉트
        */
        return Promise.resolve();
    },
    getPermissions: () =>
        Promise.resolve({
            task: {
                create: true,
                read: true,
                update: true,
                delete: true,
            },
            crm: {
                create: true,
                read: true,
                update: true,
                delete: true,
            },
            system: {
                create: true,
                read: true,
                update: true,
                delete: true,
            },
            account_mgmt: {
                access: true,
            },
        }),
    getIdentity: () => {
        if (localStorage.getItem("token")) {
            const id = localStorage.getItem("userEmail") as string;
            const fullName = localStorage.getItem("userName") as string;

            if (!id || !fullName) return Promise.reject();

            return Promise.resolve({
                id,
                fullName,
            });
        } else {
            return Promise.reject();
        }
    },
});
