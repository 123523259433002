import * as React from "react";
import { useState, useContext } from "react";
import {
    Identifier,
    useListContext,
    DataProviderContext,
    useGetIdentity,
    useDeepCompareEffect,
    useListFilterContext,
} from "react-admin";
import { Box } from "@mui/material";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { CrmDeal } from "../types";
import { CardColumn } from "@src/components/dnd/CardColumn";
import { DealCard } from "./DealCard";
import { CommonCodeDesc } from "@src/types";

export interface RecordMap {
    [id: number]: CrmDeal;
    [id: string]: CrmDeal;
}
interface DealsByColumn {
    [dealStatus: string]: Identifier[];
}

const getInitialDeals: (dealStatus: CommonCodeDesc[]) => DealsByColumn = dealStatus => {
    return dealStatus.reduce((obj, { parent_code, code }) => ({ ...obj, [`${parent_code}.${code}`]: [] }), {});
};

const getDealsByColumn = (dealStatus: CommonCodeDesc[], data: CrmDeal[]): DealsByColumn => {
    // group deals by column
    const columns = data.reduce(
        (acc, record) => {
            const status = `${record.deal_status.parent_code}.${record.deal_status.code}`;
            acc[status].push(record);
            return acc;
        },
        dealStatus.reduce((obj, { parent_code, code }) => ({ ...obj, [`${parent_code}.${code}`]: [] }), {} as any),
    );
    // order each column by index
    dealStatus.forEach(({ parent_code, code }) => {
        const status = `${parent_code}.${code}`;
        columns[status] = columns[status]
            .sort((recordA: CrmDeal, recordB: CrmDeal) => recordA.deal_status_order - recordB.deal_status_order)
            .map((deal: CrmDeal) => deal.id);
    });
    return columns;
};

const indexById = (data: CrmDeal[]): RecordMap => data.reduce((obj, record) => ({ ...obj, [record.id]: record }), {});

export const DealCardListContent = ({ dealStatus }: { dealStatus: CommonCodeDesc[] }) => {
    // const resource = useResourceContext();
    // const [{ perPage }, { setPerPage }] = useListParams({ resource });
    const { data: unorderedDeals, isLoading, refetch, sort, setSort, perPage, setPerPage } = useListContext<CrmDeal>();
    const { filterValues, setFilters, displayedFilters } = useListFilterContext();
    const [data, setData] = useState<RecordMap>(isLoading ? {} : indexById(unorderedDeals));
    const [deals, setDeals] = useState<DealsByColumn>(
        isLoading ? getInitialDeals(dealStatus) : getDealsByColumn(dealStatus, unorderedDeals),
    );
    // we use the raw dataProvider to avoid too many updates to the Redux store after updates (which would create junk)
    const dataProvider = useContext(DataProviderContext);
    const { identity } = useGetIdentity();
    // update deals by columns when the dataProvider response updates
    useDeepCompareEffect(() => {
        if (isLoading) return;
        if (filterValues && filterValues.deal_stash) {
            setFilters({ ...filterValues, deal_stash: false }, displayedFilters);
        }
        if (perPage !== 500) {
            setPerPage(500);
        }
        if (sort.field === "id") {
            setSort({
                field: "deal_status_order",
                order: "ASC",
            });
        }
        const notStashedDeals = unorderedDeals.filter(item => item.deal_stash == false);
        setDeals(getDealsByColumn(dealStatus, notStashedDeals));
        setData(indexById(notStashedDeals));
    }, [dealStatus, unorderedDeals, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
    if (isLoading || perPage !== 500 || filterValues.deal_stash) return null;

    const onDragEnd: OnDragEndResponder = async result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // moving deal inside the same column

            const column = Array.from(deals[source.droppableId]); // [4, 7, 23, 5] array of ids
            const sourceDeal = data[column[source.index]];
            const destinationDeal = data[column[destination.index]];

            // update local state
            // remove source deal from column
            column.splice(source.index, 1);
            // read source deal at destination
            column.splice(destination.index, 0, Number(draggableId));
            setDeals({
                ...deals,
                [source.droppableId]: column,
            });

            // update backend
            // Fetch all the deals in this deal_status (because the list may be filtered, but we need to update even non-filtered deals)
            const { data: columnDeals } = await dataProvider.getList("crm/deal", {
                sort: { field: "deal_status_order", order: "ASC" },
                pagination: { page: 1, perPage: 100 },
                filter: { deal_status: source.droppableId },
            });

            if (source.index > destination.index) {
                // deal moved up, eg
                // dest   src
                //  <------
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all deals between destination.index and source.index, increase the index
                    ...columnDeals
                        .filter(
                            deal =>
                                deal.deal_status_order >= destinationDeal.deal_status_order &&
                                deal.deal_status_order < sourceDeal.deal_status_order,
                        )
                        .map(deal =>
                            dataProvider.update("crm/deal", {
                                id: deal.id,
                                data: {
                                    dealInput: {
                                        id: deal.id,
                                        deal_status_order: deal.deal_status_order + 1,
                                        update_userid: `${identity?.id}(${identity?.fullName})`,
                                    },
                                },
                                previousData: deal,
                            }),
                        ),
                    // for the deal that was moved, update its index
                    dataProvider.update("crm/deal", {
                        id: sourceDeal.id,
                        data: {
                            dealInput: {
                                id: sourceDeal.id,
                                deal_status_order: destinationDeal.deal_status_order,
                                update_userid: `${identity?.id}(${identity?.fullName})`,
                            },
                        },
                        previousData: sourceDeal,
                    }),
                ]);

                refetch();
            } else {
                // deal moved down, e.g
                // src   dest
                //  ------>
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all deals between source.index and destination.index, decrease the index
                    ...columnDeals
                        .filter(
                            deal =>
                                deal.deal_status_order <= destinationDeal.deal_status_order &&
                                deal.deal_status_order > sourceDeal.deal_status_order,
                        )
                        .map(deal =>
                            dataProvider.update("crm/deal", {
                                id: deal.id,
                                data: {
                                    dealInput: {
                                        id: deal.id,
                                        deal_status_order: deal.deal_status_order - 1,
                                        update_userid: `${identity?.id}(${identity?.fullName})`,
                                    },
                                },
                                previousData: deal,
                            }),
                        ),
                    // for the deal that was moved, update its index
                    dataProvider.update("crm/deal", {
                        id: sourceDeal.id,
                        data: {
                            dealInput: {
                                id: sourceDeal.id,
                                deal_status_order: destinationDeal.deal_status_order,
                                update_userid: `${identity?.id}(${identity?.fullName})`,
                            },
                        },
                        previousData: sourceDeal,
                    }),
                ]);

                refetch();
            }
        } else {
            // moving deal across columns

            const sourceColumn = Array.from(deals[source.droppableId]); // [4, 7, 23, 5] array of ids
            const destinationColumn = Array.from(deals[destination.droppableId]); // [4, 7, 23, 5] arrays of ids
            const sourceDeal = data[sourceColumn[source.index]];
            const destinationDeal = data[destinationColumn[destination.index]]; // may be undefined if dropping at the end of a column

            // update local state
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setDeals({
                ...deals,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            // update backend
            // Fetch all the deals in both deal_status (because the list may be filtered, but we need to update even non-filtered deals)
            const [{ data: sourceDeals }, { data: destinationDeals }] = await Promise.all([
                dataProvider.getList("crm/deal", {
                    sort: { field: "deal_status_order", order: "ASC" },
                    pagination: { page: 1, perPage: 100 },
                    filter: { deal_status: source.droppableId },
                }),
                dataProvider.getList("crm/deal", {
                    sort: { field: "deal_status_order", order: "ASC" },
                    pagination: { page: 1, perPage: 100 },
                    filter: { deal_status: destination.droppableId },
                }),
            ]);

            await Promise.all([
                // decrease index on the deals after the source index in the source columns
                ...sourceDeals
                    .filter(deal => deal.deal_status_order > sourceDeal.deal_status_order)
                    .map(deal =>
                        dataProvider.update("crm/deal", {
                            id: deal.id,
                            data: {
                                dealInput: {
                                    id: deal.id,
                                    deal_status_order: deal.deal_status_order - 1,
                                    update_userid: `${identity?.id}(${identity?.fullName})`,
                                },
                            },
                            previousData: deal,
                        }),
                    ),
                // increase index on the deals after the destination index in the destination columns
                ...destinationDeals
                    .filter(deal =>
                        destinationDeal ? deal.deal_status_order >= destinationDeal.deal_status_order : false,
                    )
                    .map(deal =>
                        dataProvider.update("crm/deal", {
                            id: deal.id,
                            data: {
                                dealInput: {
                                    id: deal.id,
                                    deal_status_order: deal.deal_status_order + 1,
                                    update_userid: `${identity?.id}(${identity?.fullName})`,
                                },
                            },
                            previousData: deal,
                        }),
                    ),
                // change the dragged deal to take the destination index and column
                dataProvider.update("crm/deal", {
                    id: sourceDeal.id,
                    data: {
                        dealInput: {
                            id: sourceDeal.id,
                            deal_status_order: destinationDeal
                                ? destinationDeal.deal_status_order
                                : destinationDeals.length !== 0
                                ? destinationDeals.pop().deal_status_order + 1
                                : 0,
                            deal_status: destination.droppableId,
                            update_userid: `${identity?.id}(${identity?.fullName})`,
                        },
                    },
                    previousData: sourceDeal,
                }),
            ]);

            refetch();
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {dealStatus.map(({ parent_code, code, code_description }: CommonCodeDesc) => (
                    <CardColumn
                        title={code_description}
                        status={`${parent_code}.${code}`}
                        ids={deals[`${parent_code}.${code}`]}
                        data={data}
                        key={`${parent_code}.${code}`}
                        card={<DealCard />}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
};
