import * as React from "react";
import { CreateBase, CreateProps, TopToolbar, ListButton, useTranslate, Title, useGetIdentity } from "react-admin";
import { ClientInputs } from "./ClientInputs";
import { ClientInput } from "../types";

export const ClientCreate = (props: CreateProps) => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    if (!identity) return null;

    return (
        <CreateBase
            {...props}
            transform={({
                id,
                client_name,
                client_type,
                address,
                address_detail,
                thumbnail,
                tel_number,
                website,
                bigo_note,
            }: ClientInput) => ({
                id,
                clientInput: {
                    client_name,
                    client_type: "CRM_CLIENT_TYPE." + client_type,
                    address,
                    address_detail,
                    create_userid: `${identity?.id}(${identity?.fullName})`,
                    thumbnail: thumbnail ? thumbnail.rawFile : null,
                    tel_number,
                    website,
                    bigo_note,
                },
            })}
        >
            <Title title={translate("crm.addClient")} />
            <TopToolbar>
                <ListButton />
            </TopToolbar>
            <ClientInputs />
        </CreateBase>
    );
};
