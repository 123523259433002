import * as React from "react";
import { Avatar } from "@mui/material";
import { useRecordContext } from "react-admin";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import DomainRoundedIcon from "@mui/icons-material/DomainRounded";
import EvStationRoundedIcon from "@mui/icons-material/EvStationRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";

interface ClientAvatarProps {
    isInput: boolean;
    watch: any;
    sx: any;
    clientType: string;
}

export const ClientAvatar = ({ isInput = false, watch, sx = {}, clientType }: Partial<ClientAvatarProps>) => {
    const record = useRecordContext();
    const hasThumbnail = record && record.thumbnail;

    let bgcolor = "none";
    if (isInput) {
        if (watch) {
            let src = "";
            if (watch.src) {
                src = watch.src;
            } else {
                const { base64 } = JSON.parse(watch);
                src = base64;
            }
            return (
                <Avatar
                    variant="rounded"
                    src={src}
                    sx={{
                        ...sx,
                        bgcolor,
                        "& img": {
                            objectFit: "contain",
                            background: "white",
                        },
                    }}
                />
            );
        }
    } else if (hasThumbnail) {
        const { base64 } = JSON.parse(record.thumbnail);
        const src = base64;
        return (
            <Avatar
                variant="rounded"
                src={src}
                sx={{
                    ...sx,
                    bgcolor,
                    "& img": { objectFit: "contain", background: "white" },
                }}
            />
        );
    }

    const type = clientType ? clientType : record && record.client_type ? record.client_type.code : "";
    let icon = <HomeRoundedIcon />;
    switch (type) {
        case "CRM_CT_01": // 공동주택
            icon = <ApartmentRoundedIcon />;
            bgcolor = "#ffd17f";
            break;
        case "CRM_CT_02": // 충전기제조사
            icon = <EvStationRoundedIcon />;
            bgcolor = "#ff8584";
            break;
        case "CRM_CT_03": // 공공기관
            icon = <DomainRoundedIcon />;
            bgcolor = "#9bc9ff";
            break;
        case "CRM_CT_04": // 협력사
            icon = <CorporateFareRoundedIcon />;
            bgcolor = "#87ccb5";
            break;
        default: // 기타
    }

    return (
        <Avatar
            variant="rounded"
            sx={{
                ...sx,
                bgcolor,
            }}
        >
            {icon}
        </Avatar>
    );
};
