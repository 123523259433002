/*
    Path-GraphQLResource Map
*/
export const pathResourceMap: any = {
    CommonCode: "CommonCode",

    //인터넷 정보
    "info_retrieval/search": "Search",
    "info_retrieval/news": "News",
    "info_retrieval/law": "Law",
    "info_retrieval/bookmark": "InfoRetrievalBookmark",

    // CRM
    "crm/client": "CrmClient",
    "crm/deal": "CrmDeal",
    "crm/deal_note": "CrmDealNote",
    "crm/contact": "CrmContact",
    "crm/contact_note": "CrmContactNote",

    // VOC
    task: "Task",
    "task/note": "TaskNote",
};
