import * as React from "react";
import { useTranslate, TextField, FunctionField, useRecordContext } from "react-admin";
import { Box, Typography, Divider } from "@mui/material";
import { format } from "date-fns";
import { getUserName } from "@src/utils/utils";

export const ClientAside = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    if (!record) return null;
    return (
        <Box ml={4} width={250} minWidth={250}>
            <Box>
                <Typography variant="body2" color="textSecondary" component="span">
                    {translate("crm.clientInfo")}
                </Typography>{" "}
            </Box>
            <Divider />
            <Box mt={1}>
                <Typography variant="body2" color="textSecondary" component="span">
                    {translate("common.address")}
                </Typography>{" "}
                <TextField source="address" /> <TextField source="address_detail" />
            </Box>
            <Box mt={1}>
                <Typography variant="body2" color="textSecondary" component="span">
                    {translate("common.tel_number")}
                </Typography>{" "}
                <TextField source="tel_number" />
            </Box>
            <Box mt={1}>
                <Typography variant="body2" color="textSecondary" component="span">
                    {translate("common.website")}
                </Typography>{" "}
                <a target="_blank" href={record.website}>
                    <TextField source="website" />
                </a>
            </Box>
            <Box mt={1}>
                <Typography variant="body2" color="textSecondary" component="span">
                    {translate("common.bigo_note")}
                </Typography>{" "}
                <TextField source="bigo_note" />
            </Box>

            <Box mt={5}>
                <Typography variant="body2" color="textSecondary">
                    {translate("common.contentInfo")}
                </Typography>
            </Box>
            <Divider />
            <Box mt={1}>
                <Typography variant="body2" color="textSecondary" component="span">
                    {translate("common.createUserId")}
                </Typography>{" "}
                <Typography variant="body2" color="textPrimary" component="span">
                    {getUserName(record.create_userid)}
                </Typography>
            </Box>
            <Box mt={1}>
                <Typography variant="body2" color="textSecondary" component="span">
                    {translate("common.createAt")}
                </Typography>{" "}
                <Typography variant="body2" color="textPrimary" component="span">
                    {format(new Date(Number(record.create_at)), "yyyy-MM-dd")}
                </Typography>
            </Box>
            {record.update_userid && record.update_at ? (
                <>
                    <Box mt={1}>
                        <Typography variant="body2" color="textSecondary" component="span">
                            {translate("common.updateUserId")}
                        </Typography>{" "}
                        <Typography variant="body2" color="textPrimary" component="span">
                            {getUserName(record.update_userid)}
                        </Typography>
                    </Box>
                    <Box mt={1}>
                        <Typography variant="body2" color="textSecondary" component="span">
                            {translate("common.updateAt")}
                        </Typography>{" "}
                        <Typography variant="body2" color="textPrimary" component="span">
                            {format(new Date(Number(record.update_at)), "yyyy-MM-dd")}
                        </Typography>
                    </Box>
                </>
            ) : null}
        </Box>
    );
};
