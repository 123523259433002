import React, { useState, useEffect } from "react";
import {
    Button,
    CreateButton,
    ExportButton,
    FilterButton,
    Pagination,
    SelectInput,
    TextInput,
    TopToolbar,
    useDataProvider,
    useGetIdentity,
    useRemoveFromStore,
} from "react-admin";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { TaskCardList } from "./TaskCardList";
import { TaskStashList } from "./TaskStashList";
import { taskExporter } from "@src/utils/exporters";

export const TaskList = () => {
    const [isCardList, setIsCardList] = useState(true);
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [deptChoices, setDeptChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: "MACCO_DEPT" },
            });
            setDeptChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.code_name,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    const filters = [
        <TextInput label="task.requestTitle" source="request_title" />,
        <TextInput label="task.customerName" source="customer_name" />,
        <SelectInput label="task.responseDepartment" source="response_department" choices={deptChoices} />,
    ];
    if (!identity) return null;
    return (
        <>
            {isCardList ? (
                <TaskCardList
                    pagination={false}
                    perPage={isCardList ? 500 : 25}
                    filters={filters}
                    filterDefaultValues={{ task_stash: !isCardList }}
                    actions={<TaskListActions isCardList={isCardList} setIsCardList={setIsCardList} />}
                    exporter={taskExporter}
                />
            ) : (
                <TaskStashList
                    pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
                    perPage={isCardList ? 500 : 25}
                    filters={filters}
                    filterDefaultValues={{ task_stash: !isCardList }}
                    actions={<TaskListActions isCardList={isCardList} setIsCardList={setIsCardList} />}
                    exporter={taskExporter}
                />
            )}
        </>
    );
};

const TaskListActions = ({
    isCardList,
    setIsCardList,
}: {
    isCardList: boolean;
    setIsCardList: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const removeKey = useRemoveFromStore();
    return (
        <TopToolbar>
            <FilterButton />
            <ExportButton />
            <Button
                label={isCardList ? "common.stashList" : "task.taskCardList"}
                children={isCardList ? <ListIcon /> : <ViewComfyIcon />}
                onClick={() => {
                    removeKey("Task.listParams");
                    setIsCardList(!isCardList);
                }}
            />
            <CreateButton variant="contained" label="task.addTask" sx={{ marginLeft: 2 }} />
        </TopToolbar>
    );
};
