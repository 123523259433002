import * as React from "react";
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    useShowContext,
    TopToolbar,
    ListButton,
    useTranslate,
    Title,
    EditButton,
    useGetIdentity,
    usePermissions,
} from "react-admin";
import { Box, Card, CardContent, Divider, Typography, useMediaQuery } from "@mui/material";

import { ContactAvatar } from "./ContactAvatar";
import { ContactAside } from "./ContactAside";
import { NotesIterator } from "@src/components/notes";
import { CrmContact } from "../types";
import { ClientAvatar } from "../clients/ClientAvatar";
import { Theme } from "@mui/system";

export const ContactShow = () => {
    return (
        <ShowBase>
            <ContactShowContent />
        </ShowBase>
    );
};

const ContactShowContent = () => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    const { record, isLoading } = useShowContext<CrmContact>();
    if (isLoading || !record) return null;
    if (!identity) return null;
    if (isLoadingForPemission) return null;

    return (
        <>
            <Title title={translate("title.crm_contact")} />
            <TopToolbar>
                {permissions.crm?.update && <EditButton label={translate("crm.editContact")} />}
                <ListButton />
            </TopToolbar>
            <Box display="flex">
                <Box flex="1">
                    <Card>
                        <CardContent>
                            <Box display="flex">
                                <ContactAvatar />
                                <Box ml={2} flex="1">
                                    <Typography variant="h5">{record.contact_name}</Typography>
                                    <Typography variant="body2">
                                        {record.job_title} at{" "}
                                        <ReferenceField source="client_id" reference="crm/client" link="show">
                                            <TextField source="client_name" />
                                        </ReferenceField>
                                    </Typography>
                                </Box>
                                <Box>
                                    <ReferenceField source="client_id" reference="crm/client" link="show">
                                        <ClientAvatar />
                                    </ReferenceField>
                                </Box>
                            </Box>
                            <Box mt={2} mb={2}>
                                {!isDesktop && (
                                    <>
                                        <Typography variant="subtitle2">{translate("crm.personalInfo")}</Typography>
                                        <Divider />
                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.email")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.email}
                                            </Typography>
                                        </Box>

                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.office_tel_number")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.tel_number}
                                            </Typography>
                                        </Box>

                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.individual_tel_number")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.individual_tel_number}
                                            </Typography>
                                        </Box>

                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.fax_tel_number")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.fax_tel_number}
                                            </Typography>
                                        </Box>

                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.bigo_note")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.bigo_note}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <ReferenceManyField
                                target="contact_id"
                                reference="crm/contact_note"
                                sort={{ field: "create_at", order: "DESC" }}
                            >
                                <NotesIterator reference="crm/contact" />
                            </ReferenceManyField>
                        </CardContent>
                    </Card>
                </Box>
                {isDesktop && <ContactAside />}
            </Box>
        </>
    );
};
