// in src/comments.js
import * as React from "react";
import { Box, Card, CardHeader, CardContent, Typography } from "@mui/material";
import { TextField, useTranslate, useListContext, RecordContextProvider, FunctionField } from "react-admin";
import { isValidDate } from "@src/utils/date";
import { format } from "date-fns";
import { ExternalLinkField } from "@src/components/fields";
import { BookmarkField } from "@src/components/fields/BookmarkField";

const MobileNewsGrid = ({ type }: { type: string }) => {
    const { data, isLoading } = useListContext<any>();
    const translate = useTranslate();
    if (isLoading || !data || data.length === 0) {
        return null;
    }
    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{ margin: "0.5rem 0" }}>
                        <CardHeader
                            title={
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <ExternalLinkField label="common.subject" source="title" textAlign="center" />
                                    <span style={{ margin: "0 5px" }} />
                                    <BookmarkField type={type} />
                                </Box>
                            }
                            titleTypographyProps={{ variant: "body1" }}
                        />
                        <CardContent sx={{ pt: 0 }}>
                            {record.sourcename && (
                                <Typography variant="body2" gutterBottom>
                                    {translate("news.source")}
                                    :&nbsp;
                                    <TextField source="sourcename" />
                                </Typography>
                            )}
                            <Typography variant="body2" gutterBottom>
                                <TextField source="originallink" sortable={false} textAlign="center" />
                            </Typography>
                            {record.pubDate && (
                                <FunctionField
                                    label="common.date"
                                    sortable={false}
                                    textAlign="center"
                                    render={(record: any) => {
                                        let date = new Date(record.pubDate);
                                        if (!isValidDate(date)) {
                                            // blog 날짜 응답 format이 "yyyyMMdd"여서 파싱 작업이 필요
                                            if (/^(\d){8}$/.test(record.pubDate)) {
                                                const y = record.pubDate.substr(0, 4);
                                                const m = record.pubDate.substr(4, 2) - 1;
                                                const d = record.pubDate.substr(6, 2);
                                                date = new Date(y, m, d);
                                            }
                                        }
                                        return format(date, "yyyy-MM-dd");
                                    }}
                                />
                            )}
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

MobileNewsGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileNewsGrid;
