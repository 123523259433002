import * as React from "react";
import { Avatar } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

type TaskAvatarProps = {
    dept: string;
    sx?: Object;
};

export const TaskAvatar = ({ dept, sx = {} }: TaskAvatarProps) => {
    let icon: JSX.Element | string = <QuestionMarkIcon />;
    let bgcolor = "none";

    switch (dept) {
        case "MACCO_DEPT_01":
            icon = "CX";
            bgcolor = "#ffd17f";
            break;
        case "MACCO_DEPT_02":
            icon = "전략영업";
            bgcolor = "#ff8584";
            break;
        case "MACCO_DEPT_03":
            icon = "영업";
            bgcolor = "#9bc9ff";
            break;
        case "MACCO_DEPT_04":
            icon = "LAB";
            bgcolor = "#87ccb5";
            break;
        case "MACCO_DEPT_05":
            icon = "IT";
            bgcolor = "#ac94bf";
            break;
        case "MACCO_DEPT_06":
            icon = "전략기획";
            bgcolor = "#9bccca";
            break;
        case "MACCO_DEPT_07":
            icon = "BI";
            bgcolor = "#96a6c6";
            break;
        case "MACCO_DEPT_08":
            icon = "경영";
            bgcolor = "#69b879";
            break;
        default:
            bgcolor = "none";
    }

    return (
        <Avatar
            variant="rounded"
            sx={{
                ...sx,
                bgcolor,
            }}
        >
            {icon}
        </Avatar>
    );
};
