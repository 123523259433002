import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Box, Card, CardContent, Theme, useMediaQuery } from "@mui/material";
import {
    useDataProvider,
    useNotify,
    Form,
    required,
    TextInput,
    Toolbar,
    useTranslate,
    Button,
    Confirm,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { ___debug } from "@src/utils/utils";

const DoorayDeleteMemberToolbar = forwardRef(({ loading, onSubmit }: any, ref) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const { reset, getValues, trigger } = useFormContext();

    useImperativeHandle(ref, () => ({
        reset,
    }));

    const handleClick = () => {
        const maccoId = getValues("maccoId");
        if (maccoId === "") {
            notify("notification.account_mgmt.empty_id", { type: "warning" });
            return;
        }

        setOpen(true);
    };
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = async () => {
        const isValid = await trigger();
        if (isValid) {
            const maccoId = getValues("maccoId");
            const emailId = `${maccoId}@macco.co.kr`;
            onSubmit(emailId);
        }
        setOpen(false);
    };

    return (
        <Toolbar>
            <Button
                variant="contained"
                label="title.dooray_delete_member"
                children={<PersonRemoveIcon />}
                disabled={loading}
                onClick={handleClick}
            />
            <Confirm
                isOpen={open}
                // loading={loading}
                title={`에버온 ID [ ${getValues("maccoId")} ]를 삭제하시겠습니까?`}
                content={""}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Toolbar>
    );
});

export const DeleteMemberInputs = ({
    members,
    setRefetch,
}: {
    members: { emailId: string; doorayId: string }[];
    setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const resetRef = useRef();
    const doorayIdRef = useRef("");

    const maccoId = (maccoId: any) => {
        const emailId = `${maccoId}@macco.co.kr`;
        const filteredIds = members.filter(member => member.emailId === emailId);
        const IsAdded = filteredIds.length > 0;
        if (!IsAdded) {
            return translate("notification.account_mgmt.not_added_id");
        }
        doorayIdRef.current = filteredIds[0].doorayId;
        return undefined;
    };
    const validateId = [required(), maccoId];

    const onSubmit = async (emailId: string) => {
        setLoading(true);
        const response = await dataProvider.callExternalApi("/keycloak/deleteMember", {
            emailId,
        });

        ___debug("1. keycloak ID 조회, 계정 탈퇴 :", response);

        if (response.status !== 204) {
            notify("notification.account_mgmt.failed_to_select_keycloakId", { type: "warning" });
            setLoading(false);
            return;
        }

        // Dooray 멤버 탈퇴
        const resDelete = await dataProvider.callExternalApi(`/dooray/deleteMember?doorayId=${doorayIdRef.current}`);

        ___debug("3. Dooray 멤버 탈퇴 :", resDelete);

        if (resDelete?.data == null || resDelete?.data?.header?.isSuccessful === false) {
            notify("notification.account_mgmt.failed_to_delete_member", { type: "warning" });
            setLoading(false);
            return;
        }
        notify("notification.account_mgmt.succeeded_in_deleting_member", { type: "info" });
        if (resetRef.current) {
            (resetRef.current as any).reset();
        }
        doorayIdRef.current = "";
        setLoading(false);
        setRefetch(true);
    };

    return (
        <Box display="flex">
            <Box flex="1" mb={isSmall ? "80px" : 0}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box display="flex">
                                <Box flex="1" mt={-1}>
                                    <Box display="flex" width={isSmall ? "95%" : 400}>
                                        <TextInput
                                            label={"common.macco_id"}
                                            disabled={loading}
                                            source="maccoId"
                                            validate={validateId}
                                            fullWidth
                                            helperText={
                                                <>
                                                    1) 탈퇴 즉시 해당 멤버는 그룹웨어 서비스에 접근할 수 없습니다
                                                    <br />
                                                    2) 탈퇴 후 180일 이내 취소하지 않으면 영구 삭제되어 복구할 수
                                                    없습니다
                                                    <br />
                                                </>
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <DoorayDeleteMemberToolbar ref={resetRef} loading={loading} onSubmit={onSubmit} />
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};
