import { Card, CardContent, Typography, Box, CircularProgress, Button } from "@mui/material";
import { useTheme, useTranslate } from "react-admin";
import BusinessIcon from "@mui/icons-material/Business";
import { useDataProvider } from "@src/dataProvider";
import { useEffect, useState } from "react";

export const ShopInfo = () => {
    const [theme] = useTheme();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [shopInfo, setShopInfo] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const setShopData = async () => {
            const { data } = await dataProvider.callExternalApi("/viewData/viewShopInfo");
            setShopInfo(data.map((item: { data: any }) => JSON.parse(item.data)));
            setIsLoading(false);
        };
        setShopData();
        const interval = setInterval(setShopData, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Box display="flex" alignItems="center" marginBottom="10px" marginLeft="8px" marginTop="15px">
                {/* <Box ml={2} mr={2} display="flex">
                    <BusinessIcon color="disabled" fontSize="large" />
                </Box> */}
                <Typography variant="h6" color="#2f58aa">
                    {translate("dashboard.shop_info")}
                </Typography>
            </Box>
            <Card>
                <CardContent sx={{ minHeight: "310px", maxHeight: "260px", overflowY: "auto" }}>
                    {isLoading && (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {shopInfo.map((shopInfo: any, idx: number) => (
                        <Box
                            display="flex"
                            flexDirection="row"
                            key={idx}
                            sx={{
                                margin: "0.5em 0",
                                backgroundColor: theme?.palette?.mode === "dark" ? "#262626" : "#f9fafa",
                                padding: "0.5em 0",
                                borderRadius: "10px",
                                justifyContent: "space-between",
                                //justifyContent: "flex-start",
                            }}
                        >
                            <Box
                                component="img"
                                src={`/assets/shopicon/${shopInfo.SHOPID}.png`}
                                sx={{
                                    height: 32,
                                    mt: 0.6, // margin-top
                                    ml: 1, // margin-left
                                    mr: 1, // margin-right
                                    p: 0,
                                }}
                            />
                            <Box sx={{ ml: 0, width: 300 }}>
                                <Typography
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textAlign: "left",
                                        alignItems: "left",
                                    }}
                                >
                                    {shopInfo.COM_NM}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" alignItems="left">
                                    {`대표: ${shopInfo.COM_CEO}`}
                                    {/* <br />
                                    {`(T) ${shopInfo.COM_TEL}`} */}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Button
                                    sx={{
                                        p: "5px 3px",
                                        mr: "10px",
                                        fontSize: "0.8rem",
                                        color: theme?.palette?.mode === "dark" ? "#F9F7F9" : "#262626",
                                    }}
                                    variant="outlined"
                                    onClick={() => {
                                        window.open(shopInfo.HOME_URL);
                                    }}
                                >
                                    홈페이지
                                </Button>
                                <Button
                                    sx={{
                                        p: "5px 3px",
                                        mr: "10px",
                                        fontSize: "0.8rem",
                                        color: theme?.palette?.mode === "dark" ? "#F9F7F9" : "#262626",
                                    }}
                                    variant="outlined"
                                    onClick={() => {
                                        window.open(shopInfo.ADMIN_URL);
                                    }}
                                >
                                    회사정보
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};
