import React, { useRef } from "react";
import { ToggleButton, ToggleButtonProps } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { useTiptapEditor } from "ra-input-rich-text";
import { useTranslate } from "react-admin";

export const RichTextInputImageButton = (props: Omit<ToggleButtonProps, "value">) => {
    const editor = useTiptapEditor();
    const translate = useTranslate();
    const inputFile = useRef<HTMLInputElement>(null);

    const label = translate("common.attachImage");
    const handleChange = (event: any) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const base64 = fileReader.result;
            if (base64) {
                editor.commands.setImage({ src: base64.toString() });
            }
        };
        fileReader.readAsDataURL(event.target.files[0]);
    };

    return (
        <>
            <ToggleButton
                aria-label={label}
                title={label}
                {...props}
                disabled={!editor?.isEditable}
                value="clear"
                onClick={() => {
                    if (inputFile && inputFile.current) {
                        inputFile.current.click();
                    }
                }}
            >
                <InsertPhotoIcon fontSize="inherit" />
            </ToggleButton>
            <input
                ref={inputFile}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleChange}
            ></input>
        </>
    );
};
