import { Card, CardContent, Typography, Box, CircularProgress } from "@mui/material";
import { useGetIdentity, useTranslate } from "react-admin";
import { format } from "date-fns";
import ApprovalIcon from "@mui/icons-material/Approval";
import { useDataProvider } from "@src/dataProvider";
import { useEffect, useState } from "react";

export const LatestApprovals = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [approvals, setApprovals] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { identity, isLoading: isIdentityLoading } = useGetIdentity();

    useEffect(() => {
        if (isIdentityLoading) return;

        const getApprovals = async () => {
            const { data } = await dataProvider.callExternalApi(`/approval_list?email=${identity?.id}`, null, "GET");
            const { content } = JSON.parse(data);
            setApprovals(content);
            setIsLoading(false);
        };

        getApprovals();
        const interval = setInterval(getApprovals, 60 * 1000);
        return () => clearInterval(interval);
    }, [isIdentityLoading]);

    return (
        <div>
            {/* <Box display="flex" alignItems="center" marginBottom="1em"> */}
            <Box display="flex" alignItems="center" marginBottom="10px" marginLeft="8px">
                {/* <Box ml={2} mr={2} display="flex">
                    <ApprovalIcon color="disabled" fontSize="large" />
                </Box> */}
                {/* <Typography variant="h6" color="textPrimary"> */}
                <Typography variant="h6" color="#2f58aa">
                    {translate("dashboard.approval")}
                </Typography>
            </Box>
            <Card>
                <CardContent sx={{ minHeight: "275px", maxHeight: "275px", overflowY: "auto" }}>
                    {isLoading && (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {!isLoading && approvals.length === 0 && (
                        <Box display="flex" flexDirection="row" sx={{ margin: "1em 0" }}>
                            <Typography variant="body2" color="textSecondary">
                                등록된 전자결재 내용이 없습니다
                            </Typography>
                        </Box>
                    )}
                    {approvals.map((approval: any) => (
                        <Box
                            display="flex"
                            flexDirection="row"
                            key={approval.aprvDocId}
                            sx={{ margin: "0.8em 0", cursor: "pointer" }}
                            onClick={() => {
                                window.open(approval.docUri, "_blank");
                            }}
                        >
                            <Box ml={2}>
                                <Typography
                                    sx={{
                                        color: (theme: any) =>
                                            approval.cmpltDt !== null
                                                ? theme.palette.grey.A700
                                                : theme.palette.warning.dark,
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                    }}
                                >
                                    {approval.docTitle}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {/* {`${approval.categoryNm} (${approval.dfmNm})`}
                                    <br />
                                    {format(new Date(approval.drftDt), "yyyy-MM-dd")} */}
                                    {format(new Date(approval.drftDt), "yyyy-MM-dd HH:mm")}
                                    {` (${approval.dfmNm})`}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};
