import * as React from "react";
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    email,
    regex,
    useTranslate,
    Form,
    Toolbar,
    FileInput,
    FileField,
} from "react-admin";
import { Card, CardContent, Box, useMediaQuery, Theme, Grid } from "@mui/material";
import { ContactAvatar } from "./ContactAvatar";
import { isObject } from "lodash";
import { dataURLtoFile } from "../../../utils/file";
import { useWatch } from "react-hook-form";

export const ContactInputs = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const translate = useTranslate();
    return (
        <Box display="flex">
            <Box flex="1" mb={isSmall ? "80px" : 0}>
                <Form>
                    <Card>
                        <CardContent>
                            {isSmall && (
                                <Box flex="1" mt={1} mb={2}>
                                    <ContactAvatarInInputs />
                                </Box>
                            )}
                            <Box display="flex">
                                {!isSmall && (
                                    <Box mr={2}>
                                        <ContactAvatarInInputs />
                                    </Box>
                                )}
                                <Box flex="1" mt={-1}>
                                    <Box display="flex" width={isSmall ? "95%" : 430}>
                                        <TextInput
                                            label={"common.name"}
                                            source="contact_name"
                                            validate={[required()]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Grid container width={isSmall ? "95%" : 430}>
                                        <Grid item xs={12} sm={6} pr={isSmall ? 0 : 2}>
                                            <TextInput label={"crm.job_title"} source="job_title" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6} pr={isSmall ? 0 : 2}>
                                            <ReferenceInput source="client_id" reference="crm/client" perPage={1000}>
                                                <AutocompleteInput
                                                    label={"crm.clientName"}
                                                    optionText="client_name"
                                                    validate={[required()]}
                                                    fullWidth
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                    </Grid>
                                    <Box mt={2} width={isSmall ? "95%" : 430}>
                                        <TextInput
                                            label={"common.email"}
                                            source="email"
                                            validate={[required(), email()]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box display="flex" width={isSmall ? "95%" : 430}>
                                        <TextInput
                                            label={"common.office_tel_number"}
                                            source="tel_number"
                                            validate={[
                                                required(),
                                                regex(
                                                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3,4})[-. ]*(\d{4})\s*$/,
                                                    translate("validation.office_tel_number"),
                                                ),
                                            ]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box display="flex" width={isSmall ? "95%" : 430}>
                                        <TextInput
                                            label={"common.individual_tel_number"}
                                            source="individual_tel_number"
                                            validate={[
                                                regex(
                                                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3,4})[-. ]*(\d{4})\s*$/,
                                                    translate("validation.tel_number"),
                                                ),
                                            ]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box display="flex" width={isSmall ? "95%" : 430}>
                                        <TextInput
                                            label={"common.fax_tel_number"}
                                            source="fax_tel_number"
                                            validate={[
                                                regex(
                                                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3,4})[-. ]*(\d{4})\s*$/,
                                                    translate("validation.fax_tel_number"),
                                                ),
                                            ]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box mt={2} width={isSmall ? "95%" : 430}>
                                        <TextInput label={"common.bigo_note"} source="bigo_note" fullWidth />
                                    </Box>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <FileInput
                                            source="thumbnail"
                                            label="crm.addProfile"
                                            accept="image/*"
                                            maxSize={5000000}
                                            labelSingle="ra.input.image.upload_single"
                                            format={(data: any) => {
                                                // record -> input
                                                // https://marmelab.com/react-admin/Inputs.html#transforming-input-value-tofrom-record
                                                if (!data) return;
                                                if (isObject(data)) {
                                                    return data;
                                                } else {
                                                    const { filename, base64 } = JSON.parse(data);
                                                    const file = dataURLtoFile(base64, filename);
                                                    return {
                                                        rawFile: file,
                                                        src: URL.createObjectURL(file),
                                                        title: filename,
                                                    };
                                                }
                                            }}
                                        >
                                            <FileField source="src" title="title" target="_blank" />
                                        </FileInput>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

const ContactAvatarInInputs = () => {
    const { thumbnail }: any = useWatch();
    return <ContactAvatar isInput watch={thumbnail} />;
};

export interface ContactInput {
    id: string;
    contact_name: string;
    client_id: string;
    job_title: string;
    email: string;
    tel_number: string;
    thumbnail: any;
    individual_tel_number: string;
    fax_tel_number: string;
    bigo_note: string;
}
