import React, { useState, useEffect } from "react";
import {
    Button,
    Datagrid,
    FunctionField,
    List,
    TextField,
    useListContext,
    useListFilterContext,
    useTranslate,
    useRefresh,
    useNotify,
    useUnselectAll,
    useUpdateMany,
    useResourceContext,
    useGetIdentity,
    BulkExportButton,
    useRecordContext,
    SimpleList,
    useRedirect,
} from "react-admin";
import OutboxIcon from "@mui/icons-material/Outbox";
import { TaskShow } from "./TaskShow";
import { format } from "date-fns";
import { convertMsToProgressTime, isValidDate } from "@src/utils/date";
import { Task } from "./types";
import { Theme, useMediaQuery } from "@mui/material";
import { ko } from "date-fns/locale";

const TaskUnstashButton = () => {
    const { selectedIds } = useListContext();
    const { identity } = useGetIdentity();
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(resource);
    const [updateMany, { isLoading }] = useUpdateMany(
        resource,
        { ids: selectedIds, data: { taskInput: { task_stash: false, update_userid: identity?.id } } },
        {
            onSuccess: () => {
                refresh();
                notify("notification.unstash");
                unselectAll();
            },
            onError: error => notify("notification.unstashFail", { type: "warning" }),
        },
    );

    return (
        <Button label="common.unstash" disabled={isLoading} onClick={() => updateMany()}>
            <OutboxIcon />
        </Button>
    );
};

const TaskStashListBulkActions = () => {
    return (
        <>
            <BulkExportButton />
            <TaskUnstashButton />
        </>
    );
};

// const ProgressTimeField = ({ label, source, textAlign }: any) => {
//     const record = useRecordContext();
//     let progressTimeColor = "#00c694";
//     if (record.response_time_to_complete > 0) {
//         const days = Math.floor(record.response_time_to_complete / 1000 / 60 / 60 / 24);
//         if (days > 4) {
//             progressTimeColor = "red";
//         } else if (days > 2) {
//             progressTimeColor = "orange";
//         }
//     }
//     const progressTime = convertMsToProgressTime(+record.response_time_to_complete);

//     return <span style={{ color: progressTimeColor }}>{progressTime}</span>;
// };

const ListContent = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [showInfo, setShowInfo] = useState({ open: false, id: -1 });
    const { data, isLoading, refetch, sort, setSort, perPage, setPerPage } = useListContext();
    const { filterValues, setFilters, displayedFilters } = useListFilterContext();
    const dateFnsFormat = format;

    useEffect(() => {
        if (isLoading) return;
        if (filterValues && !filterValues.task_stash) {
            setFilters({ ...filterValues, task_stash: true }, displayedFilters);
        }
        if (perPage === 500) {
            setPerPage(25);
        }
        if (sort.field === "task_status_order") {
            setSort({
                field: "id",
                order: "DESC",
            });
        }
        refetch();
    }, [isLoading, filterValues, data]);
    if (isLoading || perPage === 500 || !filterValues.task_stash || !data) return null;

    return (
        <>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.request_title}
                    secondaryText={record => {
                        return <span>{`${record.customer_name}, ${record.response_department.code_name}, `}</span>;
                    }}
                    tertiaryText={record => {
                        const date = new Date(+(record.inbound_at as string));
                        if (!isValidDate(date)) {
                            return "invalid Date";
                        }
                        return dateFnsFormat(date, "yyyy-MM-dd");
                    }}
                    linkType={(record: any) => {
                        return `/task?id=${record.id}`;
                    }}
                    onClick={() => {
                        const idx = location.hash.indexOf("id");
                        if (idx !== -1) {
                            const id = location.hash.substring(idx + 3);
                            if (!showInfo.open) {
                                setShowInfo({
                                    open: true,
                                    id: +id,
                                });
                            }
                        }
                    }}
                />
            ) : (
                <>
                    <Datagrid
                        rowClick={(id, resource, record) => {
                            setShowInfo({
                                open: true,
                                id: +record.id,
                            });
                            return "";
                        }}
                        bulkActionButtons={<TaskStashListBulkActions />}
                    >
                        <TextField label="common.index" source="id" textAlign="center" sortable={false} />
                        <TextField
                            label="task.customerName"
                            source="customer_name"
                            textAlign="center"
                            sortable={false}
                        />
                        <TextField
                            label="task.requestTitle"
                            source="request_title"
                            textAlign="center"
                            sortable={false}
                        />
                        <FunctionField
                            label="task.responseDepartment"
                            source="response_department"
                            textAlign="center"
                            render={(record: any) => {
                                if (!record.response_department) return "";

                                return record.response_department.code_name;
                            }}
                        />
                        <FunctionField
                            label="task.inboundAt"
                            source="inbound_at"
                            textAlign="center"
                            render={({ inbound_at }: { inbound_at: string }) => {
                                const date = new Date(+(inbound_at as string));
                                if (!isValidDate(date)) {
                                    return "invalid Date";
                                }
                                return dateFnsFormat(date, "yyyy-MM-dd");
                            }}
                        />
                        <FunctionField
                            label="task.responseCompleteAt"
                            source="response_complete_at"
                            textAlign="center"
                            render={({ response_complete_at }: { response_complete_at: string }) => {
                                const date = new Date(+(response_complete_at as string));
                                if (!isValidDate(date)) {
                                    return "invalid Date";
                                }
                                return dateFnsFormat(date, "yyyy-MM-dd");
                            }}
                        />
                        {/* <ProgressTimeField
                            label="task.responseTimeToComplete"
                            source="response_time_to_complete"
                            textAlign="center"
                        /> */}
                    </Datagrid>
                </>
            )}
            <TaskShow showInfo={showInfo} setShowInfo={setShowInfo} />
        </>
    );
};

export const TaskStashList = ({ filters, actions, filterDefaultValues, perPage, pagination, exporter }: any) => {
    const translate = useTranslate();
    return (
        <List
            pagination={pagination}
            perPage={perPage}
            sort={{ field: "id", order: "DESC" }}
            filterDefaultValues={filterDefaultValues}
            filters={filters}
            actions={actions}
            title={translate("title.task_done_list")}
            exporter={exporter}
            empty={false}
        >
            <ListContent />
        </List>
    );
};
