import { Card, CardContent, Typography, Box, CircularProgress, Button } from "@mui/material";
import { useTheme, useTranslate } from "react-admin";
import BusinessIcon from "@mui/icons-material/Business";
import { useDataProvider } from "@src/dataProvider";
import { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const ShopLimit = () => {
    const [theme] = useTheme();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [shopLimit, setShopLimit] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        ["&.${tableCellClasses.head}"]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        ["&.${tableCellClasses.body}"]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    useEffect(() => {
        const setShopData = async () => {
            const { data } = await dataProvider.callExternalApi("/viewData/viewGuarLimit");
            setShopLimit(data.map((item: { data: any }) => JSON.parse(item.data)));
            setIsLoading(false);
        };
        setShopData();
        const interval = setInterval(setShopData, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Box display="flex" alignItems="center" marginBottom="10px" marginLeft="8px">
                {/* <Box ml={2} mr={2} display="flex">
                    <BusinessIcon color="disabled" fontSize="large" />
                </Box> */}
                <Typography variant="h6" color="#2f58aa">
                    {translate("dashboard.shop_limit")}
                </Typography>
            </Box>
            <Card>
                <CardContent sx={{ minHeight: "275px", maxHeight: "275px", overflowY: "auto" }}>
                    <Box display="flex" alignItems="left" marginLeft="8px">
                        <Typography variant="body2" color="textSecondary" alignItems="left">
                            {translate("dashboard.shop_limit_info")}
                        </Typography>
                    </Box>
                    {isLoading && (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {shopLimit.map((shopLimit: any, idx: number) => (
                        <Box
                            display="flex"
                            flexDirection="row"
                            key={idx}
                            sx={{
                                margin: "0.5em 0",
                                backgroundColor: theme?.palette?.mode === "dark" ? "#262626" : "#f9fafa",
                                padding: "0.5em 0",
                                borderRadius: "10px",
                                justifyContent: "space-between",
                                //justifyContent: "flex-start",
                            }}
                        >
                            <Box sx={{ ml: 0, width: 200 }}>
                                <Typography
                                    sx={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textAlign: "left",
                                        alignItems: "left",
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {shopLimit.COM_NM}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    sx={{
                                        color: (theme: any) =>
                                            shopLimit.REST_RATE > 10
                                                ? theme.palette.info.dark
                                                : theme.palette.info.dark,
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        fontSize: "0.87rem",
                                    }}
                                >
                                    {shopLimit.REST_LIMIT}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" alignItems="left" fontSize="small">
                                    {translate("dashboard.shop_limit_money")}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    sx={{
                                        color: (theme: any) =>
                                            shopLimit.REST_RATE > 5
                                                ? theme.palette.warning.main
                                                : theme.palette.error.dark,
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        fontSize: "0.877rem",
                                    }}
                                >
                                    {shopLimit.REST_RATE}%
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </CardContent>
            </Card>
            {/* <TableContainer component={Paper}>
                <Table sx={{ minHeight: "275px", maxHeight: "275px" }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">{translate("dashboard.shop_limit_nm")}</StyledTableCell>
                            <StyledTableCell align="right">{translate("dashboard.shop_limit_rate")}</StyledTableCell>
                            <StyledTableCell align="right">{translate("dashboard.shop_limit_money")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shopLimit.map((shopLimit: any, idx: number) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell component="th" scope="row">
                                    {shopLimit.COM_NM}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Typography
                                        sx={{
                                            color: (theme: any) =>
                                                shopLimit.REST_RATE > 5
                                                    ? theme.palette.grey.A700
                                                    : theme.palette.warning.dark,
                                            fontSize: "0.877rem",
                                        }}
                                    >
                                        {shopLimit.REST_RATE}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Typography
                                        sx={{
                                            color: (theme: any) =>
                                                shopLimit.REST_RATE > 5
                                                    ? theme.palette.grey.A700
                                                    : theme.palette.warning.dark,
                                            display: "-webkit-box",
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            fontSize: "0.877rem",
                                        }}
                                    >
                                        {shopLimit.REST_LIMIT}
                                    </Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
        </div>
    );
};
