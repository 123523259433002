import React, { useState } from "react";
import { List, useGetList, useTranslate } from "react-admin";
import { useLocation } from "react-router";
import { TaskCardListContent } from "./TaskCardListContent";
import { TaskShow } from "./TaskShow";
import queryString from "query-string";

export const TaskCardList = ({ filters, actions, filterDefaultValues, perPage, pagination, exporter }: any) => {
    const { search } = useLocation();
    const query = queryString.parse(search);
    const defaultShowInfo =
        query.id != null
            ? {
                  open: true,
                  id: +query.id,
              }
            : {
                  open: false,
                  id: -1,
              };
    const translate = useTranslate();
    const [showInfo, setShowInfo] = useState(defaultShowInfo);
    const { data, isLoading } = useGetList("CommonCode", {
        sort: { field: "code_order", order: "ASC" },
        filter: { parent_code: "TASK_STATUS" },
    });

    if (isLoading || !data) return null;

    return (
        <>
            <List
                pagination={pagination}
                perPage={perPage}
                sort={{ field: "task_status_order", order: "ASC" }}
                filterDefaultValues={filterDefaultValues}
                filters={filters}
                actions={actions}
                component="div"
                title={translate("title.task")}
                exporter={exporter}
            >
                <TaskCardListContent
                    taskStatus={data.map(({ parent_code, code, code_description, code_name, code_order }) => ({
                        parent_code,
                        code,
                        code_description,
                        code_name,
                        code_order,
                    }))}
                    setShowInfo={setShowInfo}
                />
            </List>
            <TaskShow showInfo={showInfo} setShowInfo={setShowInfo} />
        </>
    );
};
