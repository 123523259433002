import * as React from "react";
import { useListFilterContext, useGetIdentity, useTranslate } from "react-admin";
import { Switch, FormControlLabel, Box } from "@mui/material";

export const OnlyMineInput = (props: { label: string }) => {
    const { filterValues, displayedFilters, setFilters } = useListFilterContext();
    const { identity } = useGetIdentity();
    const translate = useTranslate();
    const handleChange = () => {
        const newFilterValues = { ...filterValues };
        if (typeof filterValues.create_userid !== "undefined") {
            delete newFilterValues.create_userid;
        } else {
            newFilterValues.create_userid = identity && identity?.id;
        }
        setFilters(newFilterValues, displayedFilters);
    };
    return (
        <Box sx={{ marginBottom: 1, marginLeft: 1 }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={typeof filterValues.create_userid !== "undefined"}
                        onChange={handleChange}
                        color="primary"
                        name="create_userid"
                    />
                }
                label={translate("crm.seeOnlyMine")}
            />
        </Box>
    );
};

OnlyMineInput.defaultProps = { source: "create_userid" };
