import { ContactShow } from "./contacts/ContactShow";
import { ContactList } from "./contacts/ContactList";
import { ContactEdit } from "./contacts/ContactEdit";
import { ContactCreate } from "./contacts/ContactCreate";

import { ClientList } from "./clients/ClientList";
import { ClientCreate } from "./clients/ClientCreate";
import { ClientShow } from "./clients/ClientShow";
import { ClientEdit } from "./clients/ClientEdit";

import { DealList } from "./deals/DealList";

export const getCrmContactResourceProps = (permission: any) => ({
    ...(!!permission.create && { create: ContactCreate }),
    ...(!!permission.read && { list: ContactList, show: ContactShow }),
    ...((!!permission.update || !!permission.delete) && { edit: ContactEdit }),
});

export const getCrmClientResourceProps = (permission: any) => ({
    ...(!!permission.create && { create: ClientCreate }),
    ...(!!permission.read && { list: ClientList, show: ClientShow }),
    ...((!!permission.update || !!permission.delete) && { edit: ClientEdit }),
});

export const getCrmDealResourceProps = (permission: any) => ({
    ...(!!permission.read && { list: DealList }),
});
