import * as React from "react";
import { useTranslate } from "react-admin";
import { Box, Theme, ListItemIcon, MenuItem, Typography } from "@mui/material";
import WebIconIcon from "@mui/icons-material/Web";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

const OrganMenuItems = ({ dense }: { dense: boolean }) => {
    const translate = useTranslate();

    return (
        <>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://www.assembly.go.kr", "_blank");
                }}
            >
                {/* <ListItemIcon>
                    <LanguageOutlinedIcon color="primary" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/assem.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_assembly`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://www.seoul.go.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/seoul.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_seoul`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("http://www.kdsa.or.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/kdsa.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_kdsa`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://kossa.or.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/kossa.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_kossa`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://www.ftc.go.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/organ.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_ftc`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://www.mof.go.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/organ.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_mof`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://www.mafra.go.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/organ.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_mafra`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://www.mfds.go.kr", "_blank");
                }}
            >
                <Box
                    component="img"
                    src="/assets/menuicon/organ.png"
                    sx={{
                        height: 20,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.rel_mfds`)}
                </Typography>
            </MenuItem>
        </>
    );
};

export default OrganMenuItems;
