import { ApolloClient, InMemoryCache, gql, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLError } from "graphql";
import { Identifier } from "react-admin";
import { fields } from "./gql/fields";
import { keycloak } from "@src/auth/keycloak";
import setLocales from "@src/configuration/setLocales";
import { pathResourceMap } from "./pathResourceMap";
import { updateAuthToken } from "@src/authProvider";

type ErrorsFromServer = { status: string; message: string }[] | readonly GraphQLError[];

const handleErrorsFromServer = (errors: ErrorsFromServer) => {
    const { translate } = setLocales;
    errors.map(error => {
        let message = error.message;
        let forceLogout = false;
        if (error.message === "User not Authenticated") {
            message = translate("notification.auth_failed");
            forceLogout = true;
        } else if (error.message.startsWith("User is not authorized")) {
            message = translate("ra.notification.not_authorized");
            forceLogout = true;
        }
        if (forceLogout) {
            setTimeout(function () {
                keycloak.logout();
            }, 2000);
        }
        throw new Error(message);
    });
};

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    return {
        headers: {
            ...headers,
            ["Apollo-Require-Preflight"]: true,
            // authorization: token ? `bearer ${token}` : "",
        },
    };
});

const uploadLink = createUploadLink({ uri: `${process.env.REACT_APP_EXPRESS_SERVER_URI as string}/graphql` });

export const useDataProvider = () => {
    const client = new ApolloClient({
        uri: `${process.env.REACT_APP_EXPRESS_SERVER_URI as string}/graphql`,
        link: ApolloLink.from([authLink, uploadLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        },
    });

    const dataProvider = {
        getList: (resource: string, { sort, pagination, filter }: any) => {
            const { field, order } = sort;
            const { page, perPage } = pagination;
            resource = pathResourceMap[resource];

            return client
                .query({
                    query: gql`

                query all${resource}($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: ${resource}Filter) {
                    items: all${resource}(
                        page: $page
                        perPage: $perPage
                        sortField: $sortField
                        sortOrder: $sortOrder
                        filter: $filter) 
                    {
                        ${fields[resource]}
                        __typename
                    }
                    total: _all${resource}Meta(page: $page, perPage: $perPage, filter: $filter) {
                        count
                        __typename
                    }
                }

                `,
                    variables: {
                        filter: filter,
                        page: page - 1,
                        perPage,
                        sortField: field,
                        sortOrder: order,
                    },
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    const {
                        data: { items, total },
                    } = result;
                    return {
                        data: items,
                        total: total.count,
                    };
                });
        },
        getOne: (resource: string, params: any) => {
            resource = pathResourceMap[resource];

            return client
                .query({
                    query: gql`


                query ${resource}($id: ID!) {
                    ${resource}(id: $id) {
                        ${fields[resource]}
                    }
                }


                `,
                    variables: {
                        id: params.id,
                    },
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }

                    return { data: result.data[resource] };
                });
        },
        getMany: (resource: string, params: any) => {
            resource = pathResourceMap[resource];

            return client
                .query({
                    query: gql`


                query all${resource}($filter: ${resource}Filter) {
                    items: all${resource}(filter: $filter) {
                        ${fields[resource]}
                        __typename
                    }
                    total: _all${resource}Meta(filter: $filter) {
                        count
                        __typename
                    }
                }

                    `,
                    variables: {
                        filter: {
                            ids: params.ids,
                        },
                    },
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    const {
                        data: { items, total },
                    } = result;
                    return {
                        data: items,
                        total: total.count,
                    };
                });
        },
        getManyReference: (resource: string, { target, id, sort, pagination, filter }: any) => {
            const { field, order } = sort;
            const { page, perPage } = pagination;
            resource = pathResourceMap[resource];

            return client
                .query({
                    query: gql`


                query all${resource}($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: ${resource}Filter) {
                    items: all${resource}(
                        page: $page
                        perPage: $perPage
                        sortField: $sortField
                        sortOrder: $sortOrder
                        filter: $filter) 
                    {
                        ${fields[resource]}
                        __typename
                    }
                    total: _all${resource}Meta(page: $page, perPage: $perPage, filter: $filter) {
                        count
                        __typename
                    }
                }

                `,
                    variables: {
                        filter: {
                            ...filter,
                            [target]: id,
                        },
                        page: page - 1,
                        perPage,
                        sortField: field,
                        sortOrder: order,
                    },
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    const {
                        data: { items, total },
                    } = result;
                    return {
                        data: items,
                        total: total.count,
                    };
                });
        },
        create: (resource: string, params: any) => {
            const strInput = Object.keys(params.data).filter(key => key != "id")[0];
            resource = pathResourceMap[resource];

            return client
                .mutate({
                    mutation: gql`


                mutation create${resource}($${strInput}: ${resource}Input) {
                    data: create${resource}(${strInput}: $${strInput}) {
                        ${fields[resource]}
                    }
                }


                `,
                    variables: {
                        [strInput]: params.data[strInput],
                    },
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    return result.data;
                });
        },
        update: (resource: string, params: any) => {
            const strInput = Object.keys(params.data).filter(key => key != "id")[0];
            resource = pathResourceMap[resource];

            return client
                .mutate({
                    mutation: gql`


                mutation update${resource}($id: ID!, $${strInput}: ${resource}Input) {
                    data: update${resource}(id: $id, ${strInput}: $${strInput}) {
                        ${fields[resource]}
                    }
                }


                `,
                    variables: {
                        id: params.id,
                        [strInput]: params.data[strInput],
                    },
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    return result.data;
                });
        },
        updateMany: (resource: string, params: any) => {
            // This provider does not support multiple deletions so instead we send multiple UPDATE requests
            // This can be optimized using the apollo-link-batch-http link
            const { ids } = params;
            return Promise.all(
                ids.map((id: any) =>
                    dataProvider.update(resource, {
                        id,
                        data: params.data,
                    }),
                ),
            ).then(results => {
                const data = results.reduce<Identifier[]>((acc, { data }) => [...acc, data.id], []);
                return { data };
            });
        },
        delete: (resource: string, params: any) => {
            const { id } = params;
            const delete_userid = localStorage.getItem("userid");
            resource = pathResourceMap[resource];

            return client
                .mutate({
                    mutation: gql`


                mutation delete${resource}($id: ID!, $delete_userid: String) {
                    data: delete${resource}(id: $id, delete_userid: $delete_userid) {
                        ${fields[resource]}
                    }
                }


                `,
                    variables: {
                        id,
                        delete_userid,
                    },
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    return result.data;
                });
        },
        deleteMany: (resource: string, params: any) => {
            // This provider does not support multiple deletions so instead we send multiple DELETE requests
            // This can be optimized using the apollo-link-batch-http link
            const { ids } = params;
            const delete_userid = localStorage.getItem("userid");
            return Promise.all(
                ids.map((id: any) => dataProvider.delete(resource, { id, meta: { delete_userid } })),
            ).then(results => {
                const data = results.reduce<Identifier[]>((acc, { data }) => [...acc, data.id], []);
                return { data };
            });
        },
        customGqlQuery: (gqlQuery: string, variables: any) => {
            return client
                .query({
                    query: gql`
                        ${gqlQuery}
                    `,
                    variables,
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }

                    return result;
                });
        },
        callExternalApi: async (endpoint: string, body?: any, method = "POST") => {
            //await updateAuthToken();
            return fetch(`${process.env.REACT_APP_EXPRESS_SERVER_URI as string}${endpoint}`, {
                method,
                headers: {
                    authorization: `bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                ...(body != null && { body: JSON.stringify(body) }),
            })
                .then(result => {
                    return result.json();
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    return result; // { data: ..., status: ... }
                })
                .catch(message => {
                    console.error(message);
                    return { data: null };
                });
        },
    };
    return dataProvider;
};
