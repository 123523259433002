import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { LatestEmails } from "./LatestEmails";
import { LatestApprovals } from "./LatestApprovals";
import { useGetIdentity } from "react-admin";
import { DamboChart } from "./DamboChart";
import { ShopInfo } from "./ShopInfo";
import { ShopLimit } from "./ShopLimit";
import { MaccoEvent } from "./MaccoEvent";
import { TabChart } from "./TabChart";

const Dashboard = () => {
    const { isLoading } = useGetIdentity();

    if (isLoading) {
        return (
            <Box textAlign="center">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Grid container spacing={4} mt={1} justifyContent="space-between">
                <Grid item xs={12} md={4}>
                    <LatestApprovals />
                </Grid>
                <Grid item xs={12} md={4}>
                    <LatestEmails />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ShopLimit />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TabChart />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ShopInfo />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MaccoEvent />
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;
