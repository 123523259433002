import { Card, CardContent, Typography, Box, CircularProgress } from "@mui/material";
import { useTranslate } from "react-admin";
import { format } from "date-fns";
import EmailIcon from "@mui/icons-material/Email";
import { useDataProvider } from "@src/dataProvider";
import { useEffect, useState } from "react";
import { updateAuthToken } from "@src/authProvider";

export const LatestEmails = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [emails, setEmails] = useState<any>([]);
    const [readStatus, setReadStatus] = useState<boolean[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const emailURL = "https://mail.worksmobile.com/#/all?isUnselected=false&mailSN=";

    useEffect(() => {
        const getEmails = async () => {
            await updateAuthToken();
            const { data } = await dataProvider.callExternalApi("/received_email", null, "GET");
            const jsonData = JSON.parse(data);

            const fechedReadStatus = jsonData.map((mail: any) => {
                return mail.status === "Read";
            });

            setEmails(jsonData);
            setReadStatus(fechedReadStatus);
            setIsLoading(false);
        };

        getEmails();
        const interval = setInterval(getEmails, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Box display="flex" alignItems="center" marginBottom="10px" marginLeft="8px">
                {/* <Box ml={2} mr={2} display="flex">
                    <EmailIcon color="disabled" fontSize="large" />
                </Box> */}
                <Typography variant="h6" color="#2f58aa">
                    {translate("dashboard.email")}
                </Typography>
            </Box>
            <Card>
                <CardContent sx={{ minHeight: "275px", maxHeight: "275px", overflowY: "auto" }}>
                    {isLoading && (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {emails.map((email: any, idx: number) => (
                        <Box
                            display="flex"
                            flexDirection="row"
                            key={email.mailId}
                            sx={{ margin: "0.8em 0", cursor: "pointer" }}
                            onClick={() => {
                                //window.open(`https://mail.worksmobile.com/#/read/all/${email.mailId}`, "_blank");
                                window.open(`${emailURL}${email.mailId}`, "_blank");
                                const newReadStatus = [...readStatus];
                                newReadStatus[idx] = true;
                                setReadStatus(newReadStatus);
                            }}
                        >
                            <Box ml={2}>
                                <Typography
                                    sx={{
                                        color: (theme: any) =>
                                            // readStatus[idx] ? theme.palette.primary.dark : theme.palette.primary.light,
                                            readStatus[idx] ? theme.palette.grey.A700 : theme.palette.warning.dark,
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                    }}
                                >
                                    {email.subject}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {/* {`${email.from.name} (${email.from.email})`}
                                    <br /> */}
                                    {format(new Date(email.receivedTime), "yyyy-MM-dd HH:mm")}
                                    {` (${email.from.name})`}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};
