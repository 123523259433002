import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Card } from "@mui/material";
import { useTranslate } from "react-admin";

export const ChartBox = ({ height = 300, titleIcon, titleText, titleTo = "", chart }: any) => {
    const translate = useTranslate();
    return (
        <>
            <Box display="flex" alignItems="center">
                <Box ml={2} mr={1} display="flex">
                    {titleIcon}
                </Box>
                <Link underline="none" variant="h6" color="textPrimary" component={RouterLink} to={titleTo}>
                    {translate(titleText)}
                </Link>
            </Box>
            <Box sx={{ display: "flex", height, width: "99%", alignItems: "center", justifyContent: "center" }}>
                {chart}
            </Box>
        </>
    );
};
