import * as React from "react";
import { EditBase, TopToolbar, ListButton, Title, useTranslate, ShowButton, useGetIdentity } from "react-admin";
import { ContactInput, ContactInputs } from "./ContactInputs";

export const ContactEdit = () => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();

    if (!identity) return null;

    return (
        <EditBase
            redirect="show"
            mutationMode="pessimistic"
            transform={({
                id,
                contact_name,
                client_id,
                job_title,
                email,
                tel_number,
                thumbnail,
                individual_tel_number,
                fax_tel_number,
                bigo_note,
            }: ContactInput) => {
                let file_change_status = ""; // NOT CHANGED
                if (thumbnail === null) {
                    file_change_status = "REMOVED";
                } else if (thumbnail && thumbnail.rawFile) {
                    file_change_status = "CHANGED";
                }
                return {
                    id,
                    contactInput: {
                        client_id,
                        contact_name,
                        email,
                        tel_number,
                        job_title,
                        update_userid: `${identity?.id}(${identity?.fullName})`,
                        file_change_status,
                        thumbnail: file_change_status === "CHANGED" ? thumbnail.rawFile : null,
                        individual_tel_number,
                        fax_tel_number,
                        bigo_note,
                    },
                };
            }}
        >
            <Title title={translate("crm.editContact")} />
            <TopToolbar>
                <ShowButton label={translate("crm.showContact")} />
                <ListButton />
            </TopToolbar>
            <ContactInputs />
        </EditBase>
    );
};
