/* global kakao */
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Button, required, TextInput, useTranslate } from "react-admin";
import { Box, Grid, Dialog, DialogContent } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import { useFormContext } from "react-hook-form";

// const useStyles = makeStyles({
//     btnSearch: {
//         marginTop: "8px",
//     },
//     mapDiv: {
//         width: "100%",
//         minHeight: "500px",
//         backgroundColor: "#f5f5f5",
//         borderRadius: "1em",
//         // padding: '12px',
//         color: "#989898",
//     },
// });

type KakaoMapInputProps = {
    basePath?: string;
    showCoordinate?: boolean;
    placeholder?: string;
    source?: string;
};

export const KakaoAddressInput = () => {
    const translate = useTranslate();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TextInput
                label={"common.address"}
                validate={[required()]}
                source="address"
                fullWidth
                disabled
                onClick={() => {
                    setOpen(true);
                }}
                helperText={translate("helperText.kakaoAddress")}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                sx={{
                    ".MuiDialog-paper": {
                        position: "absolute",
                        top: 50,
                    },
                    ".MuiDialogContent-root": {
                        padding: 0,
                    },
                }}
            >
                <DialogContent>
                    <SearchPopup handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </>
    );
};

const SearchPopup = ({ handleClose }: any) => {
    const searchPopup = useRef(null);
    const { setValue } = useFormContext();

    useEffect(() => {
        const { daum } = window as any;
        new daum.Postcode({
            oncomplete: function (data: any) {
                // 도로명주소, 우편번호 설정
                setValue("address", data.roadAddress);
                setValue("zipcode", data.zonecode);
                handleClose();
            },
            width: "100%",
            height: "100%",
        }).embed(searchPopup.current);
    }, [searchPopup]);

    return <Box sx={{ width: "100%", height: "600px" }} ref={searchPopup}></Box>;
};

// 참고용, 임시 보관
// const openSearchPopup = (setValue: any, element_wrap: any) => {
//     const { daum } = window as any;
//     const width = 500; //팝업의 너비
//     const height = 600; //팝업의 높이
//     new daum.Postcode({
//         oncomplete: function (data: any) {
//             // 도로명주소, 우편번호 설정
//             setValue("address", data.roadAddress);
//             setValue("zipcode", data.zonecode);
//         },
//     }).open({
//         left: window.screen.width / 2 - width / 2,
//         top: window.screen.height / 2 - height / 2,
//         popupTitle: "주소 검색",
//         popupKey: "kakaoAddressPopUp1", //팝업창 Key값 설정 (영문+숫자 추천)
//     });
// };

// TODO, 상세 주소 정보를 컴포넌트 렌더링 시 값으로 받아와서 설정필요
const KakaoMapInput = ({ basePath, showCoordinate = false, placeholder = "주소" }: KakaoMapInputProps) => {
    // const translate = useTranslate();
    // const record: any = useRecordContext();
    // const { zipCode, address } = record;
    const mapRef = useRef<any>();
    const [addressState, setAddressState] = useState({
        zipCode: "",
        address: "",
        xCoordi: "",
        yCoordi: "",
    });

    // const form = useForm();
    // form.change("zipcode", addressState.zipCode);
    // form.change("address", addressState.address);
    // form.change("lon", addressState.xCoordi);
    // form.change("lat", addressState.yCoordi);

    const handleClick = useCallback(() => {
        const { kakao } = window as any;
        const { daum } = window as any;

        // alert('주소찾기');
        const mapContainer = mapRef.current, // 지도를 표시할 div
            mapOption = {
                center: new kakao.maps.LatLng(37.5656490167105, 126.988142568678), // 지도의 중심좌표
                level: 3, // 지도의 확대 레벨
            };
        const map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
        //주소-좌표 변환 객체를 생성
        const geocoder = new kakao.maps.services.Geocoder();
        // 마커가 표시될 위치입니다
        // 마커를 생성합니다
        const marker = new kakao.maps.Marker({
            position: new kakao.maps.LatLng(37.5656490167105, 126.988142568678),
            map: map,
        });
        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);

        new daum.Postcode({
            oncomplete: function (data: any) {
                // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
                // TODO, https://postcode.map.daum.net/guide

                // 각 주소의 노출 규칙에 따라 주소를 조합한다.
                // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                let addr = ""; // 주소 변수
                // let extraAddr = ''; // 참고항목 변수

                //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                if (data.userSelectedType === "R") {
                    // 사용자가 도로명 주소를 선택했을 경우
                    addr = data.roadAddress;
                } else {
                    // 사용자가 지번 주소를 선택했을 경우(J)
                    addr = data.jibunAddress;
                }

                geocoder.addressSearch(data.address, function (results: any[], status: any) {
                    // 정상적으로 검색이 완료됐으면
                    if (status === daum.maps.services.Status.OK) {
                        const result = results[0]; //첫번째 결과의 값을 활용

                        // 해당 주소에 대한 좌표를 받아서
                        const coords = new daum.maps.LatLng(result.y, result.x);
                        // 지도를 보여준다.
                        mapContainer.style.display = "block";
                        map.relayout();
                        // 지도 중심을 변경한다.
                        map.setCenter(coords);
                        // 마커를 결과값으로 받은 위치로 옮긴다.
                        marker.setPosition(coords);

                        setAddressState({
                            zipCode: data.zonecode,
                            address: addr,
                            yCoordi: result.y,
                            xCoordi: result.x,
                        });
                    }
                });
            },
        }).open();
    }, []);

    return (
        <Fragment>
            <Grid container spacing={2}>
                {showCoordinate && (
                    <>
                        <Grid item xs={2}>
                            <TextInput source="lon" label={"X좌표 *"} fullWidth helperText={false} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput source="lat" label={"Y좌표 *"} fullWidth helperText={false} />
                        </Grid>
                    </>
                )}
                <Grid item xs={showCoordinate ? 4 : 8}>
                    <TextInput source="zipcode" label={"우편번호"} fullWidth helperText={false} disabled />
                </Grid>
                <Grid item xs={4} justifyContent="center" alignContent="center">
                    <Button
                        label={`${placeholder} 검색`}
                        children={<MapIcon />}
                        size="large"
                        onClick={handleClick}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="address" label={placeholder} fullWidth helperText={false} disabled />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="address2" label={"상세 주소"} fullWidth />
                </Grid>
            </Grid>
            <Box sx={{ display: showCoordinate ? "flex" : "none" }}>
                <Grid item xs={12}>
                    <div ref={mapRef} style={{ fontSize: " 0.825rem" }}>
                        <br /> &nbsp;&nbsp; 주소검색 시 지도가 표시됩니다
                    </div>
                </Grid>
            </Box>
        </Fragment>
    );
};

const KakaoMapField = ({ lat = 37.5656490167105, lng = 126.988142568678 }: { lat: number; lng: number }) => {
    const mapRef = useRef<any>();

    useEffect(() => {
        const { kakao } = window as any;
        const { daum } = window as any;
        const mapContainer = mapRef.current, // 지도를 표시할 div
            mapOption = {
                center: new kakao.maps.LatLng(lat, lng), // 지도의 중심좌표
                level: 3, // 지도의 확대 레벨
            };
        const map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
        //주소-좌표 변환 객체를 생성
        const geocoder = new kakao.maps.services.Geocoder();
        // 마커가 표시될 위치입니다
        // 마커를 생성합니다
        const marker = new kakao.maps.Marker({
            position: new kakao.maps.LatLng(lat, lng),
            map: map,
        });
        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <Grid item xs={12}>
                <div ref={mapRef}></div>
            </Grid>
        </Box>
    );
};

export { KakaoMapInput, KakaoMapField };
