import { ResponsiveLine } from "@nivo/line";
import { getNivoTheme } from "@src/layout/themes";
import { useTheme } from "react-admin";

export const LineChart = ({
    data,
    keys,
    colors = { scheme: "nivo" },
    axisRight,
    tooltip,
    valueScale,
    markers,
    indexBy = "date",
    margin = { top: 30, right: 50, bottom: 50, left: 20 },
    ...commonChartProps
}: any) => {
    const [theme] = useTheme();

    // reference - https://nivo.rocks/line/
    return (
        <ResponsiveLine
            data={data}
            colors={colors}
            keys={keys}
            indexBy={indexBy}
            theme={getNivoTheme(theme)}
            margin={margin}
            tooltip={tooltip}
            padding={0.3}
            valueScale={valueScale}
            xScale={{ type: "point" }}
            yScale={{
                type: "linear",
                min: "0",
                max: "auto",
                stacked: false,
                reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            enableGridX={false}
            enableGridY={false}
            enableLabel={false}
            axisBottom={{
                legendPosition: "middle",
                legendOffset: 50,
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 40,
            }}
            axisLeft={null}
            axisRight={axisRight}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: "top-left",
                    direction: "column",
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    toggleSerie: true,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            {...commonChartProps}
        />
    );
};
