import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Theme, useMediaQuery } from "@mui/material";
import { RichTextInputWithImageAttachment } from "@src/components/inputs/RichTextInputWithImageAttachment";
import { Form, required, SelectInput, TextInput, Toolbar, useDataProvider, useRecordContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import _ from "lodash";

export const TaskInputs = ({ handleSubmit }: any) => {
    const record = useRecordContext();
    const [customerName, setCustomerName] = useState(record?.customer_name ? record.customer_name : "");
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    return (
        <Box display="flex">
            <Box flex="1" mb={isSmall ? "80px" : 0}>
                <Form onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={3}>
                                    <CustomerTypeSelectInput />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8} sm={3} md={2}>
                                    <TextInput
                                        source="customer_name"
                                        label="task.customerName"
                                        validate={[required()]}
                                        onChange={e => setCustomerName(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2} md={2}>
                                    <CustomerInboundCountTextInput customerName={customerName} />
                                </Grid>
                                <Grid item xs={12} sm={3} md={2}>
                                    <TextInput source="inbound_contact" label="common.contact" fullWidth />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TaskInboundChannel1SelectInput />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TaskInboundChannel2SelectInput />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TaskRequestCategory1SelectInput />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TaskRequestCategory2SelectInput />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TaskRequestCategory3SelectInput />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={10} md={6}>
                                    <TextInput
                                        source="request_title"
                                        label="common.subject"
                                        validate={[required()]}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box width="100%">
                                <RichTextInputWithImageAttachment
                                    label="task.requestDescription"
                                    name="request_description"
                                    source="request_description"
                                    validate={[required()]}
                                    setEditor={null}
                                    handleChange={null}
                                />
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

const CustomerInboundCountTextInput = ({ customerName }: { customerName: string }) => {
    const dataProvider = useDataProvider();
    const [inboundCnt, setInboundCnt] = useState<number>(0);
    const { setValue } = useFormContext();

    useEffect(() => {
        const getInboundCnt = async () => {
            if (customerName === "") {
                setInboundCnt(0);
                return;
            }
            const { data } = await dataProvider.getList("task", {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: "id", order: "ASC" },
                filter: { customer_name: customerName },
            });
            setInboundCnt(data.length);
            setValue("customer_inbound_count", data.length);
        };
        getInboundCnt();
    }, [customerName]);

    return (
        <TextInput
            source="customer_inbound_count"
            label="task.customerInboundCnt"
            sx={{ width: "80px", display: "flex", textAlign: "center" }}
            disabled
            fullWidth
            value={inboundCnt}
        />
    );
};

const CustomerTypeSelectInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: "CRM_CLIENT_TYPE" },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.code_name,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    return (
        <SelectInput
            defaultValue=""
            label={"task.customerType"}
            source="customer_type"
            choices={choices}
            validate={[required()]}
            sx={{ mt: 0, mr: "1rem", display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
        />
    );
};

const TaskInboundChannel1SelectInput = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);
    const { setValue } = useFormContext();
    const [curOption, setCurOption] = useState<string>(record ? record.request_category_2 : "");

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: "VOC_INBOUND_CHANNEL" },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.code_name,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    return (
        <SelectInput
            defaultValue=""
            label={"task.inboundChannel1"}
            source={"inbound_channel_category_1"}
            choices={choices}
            validate={[required()]}
            sx={{ mt: 0, mr: "1rem", display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
            onChange={(event: any) => {
                if (event.target.value !== curOption) {
                    setValue("inbound_channel_category_2", "");
                    setCurOption(event.target.value);
                }
            }}
        />
    );
};

const TaskInboundChannel2SelectInput = () => {
    const category1 = useWatch({ name: "inbound_channel_category_1" });
    let isDisabled = true;
    if (category1 != null && category1 != "") isDisabled = false;

    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const initChoices = async () => {
            if (isDisabled) return;
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: typeof category1 === "string" ? category1 : category1.code },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.code_name,
                    };
                }),
            );
        };
        initChoices();
    }, [isDisabled, category1]);

    return (
        <SelectInput
            defaultValue=""
            label={"task.inboundChannel2"}
            source="inbound_channel_category_2"
            choices={choices}
            validate={[required()]}
            disabled={isDisabled}
            sx={{ mt: 0, mr: "1rem", display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
        />
    );
};

const TaskRequestCategory1SelectInput = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);
    const [curOption, setCurOption] = useState<string>(record ? record.request_category_1 : "");
    const { setValue } = useFormContext();

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: "VOC_REQUEST_TYPE" },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.code_name,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    return (
        <SelectInput
            defaultValue=""
            label={"task.requestCategory1"}
            source="request_category_1"
            choices={choices}
            validate={[required()]}
            sx={{ mt: 0, mr: "1rem", display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
            onChange={(event: any) => {
                if (event.target.value !== curOption) {
                    setValue("request_category_2", "");
                    setValue("request_category_3", "");
                    setCurOption(event.target.value);
                }
            }}
        />
    );
};

const TaskRequestCategory2SelectInput = () => {
    const record = useRecordContext();
    const category1 = useWatch({ name: "request_category_1" });
    const { setValue } = useFormContext();
    const dataProvider = useDataProvider();
    const [curOption, setCurOption] = useState<string>(record ? record.request_category_2 : "");
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);
    let isDisabled = true;
    if (category1 && category1 != "") isDisabled = false;

    useEffect(() => {
        if (isDisabled) return;
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: typeof category1 === "string" ? category1 : category1.code },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.code_name,
                    };
                }),
            );
        };
        initChoices();
    }, [isDisabled, category1]);

    return (
        <SelectInput
            defaultValue=""
            label={"task.requestCategory2"}
            source="request_category_2"
            choices={choices}
            validate={[required()]}
            disabled={isDisabled}
            sx={{ mt: 0, mr: "1rem", display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
            onChange={(event: any) => {
                if (event.target.value !== curOption) {
                    setValue("request_category_3", "");
                    setCurOption(event.target.value);
                }
            }}
        />
    );
};

const TaskRequestCategory3SelectInput = () => {
    const category1 = useWatch({ name: "request_category_1" });
    const category2 = useWatch({ name: "request_category_2" });
    let isDisabled = true;
    if (category1 && category1 != "" && category2 && category2 != "") isDisabled = false;

    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        if (isDisabled) return;
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "code_order", order: "ASC" },
                filter: { parent_code: typeof category2 === "string" ? category2 : category2.code },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.code_name,
                    };
                }),
            );
        };
        initChoices();
    }, [isDisabled, category1, category2]);

    return (
        <SelectInput
            defaultValue=""
            label={"task.requestCategory3"}
            source="request_category_3"
            choices={choices}
            validate={[required()]}
            disabled={isDisabled}
            sx={{ mt: 0, mr: "1rem", display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
        />
    );
};
