import { Dialog } from "@mui/material";
import { getCommonCode } from "@src/utils/utils";
import React from "react";
import {
    Edit,
    Identifier,
    ListButton,
    TopToolbar,
    useGetIdentity,
    useNotify,
    useRedirect,
    useResourceContext,
    useTranslate,
    useUpdate,
} from "react-admin";
import { TaskEditInputs } from "./types";
import { TaskInputs } from "./TaskInputs";

const EditActions = () => {
    return (
        <TopToolbar>
            <ListButton />
        </TopToolbar>
    );
};

export const TaskEdit = ({ id }: { id?: Identifier }) => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [update] = useUpdate();
    const notify = useNotify();
    const { identity } = useGetIdentity();

    const handleSubmit = (data: TaskEditInputs) => {
        const {
            id,
            inbound_at,
            inbound_channel_category_1,
            inbound_channel_category_2,
            customer_inbound_count,
            customer_name,
            customer_type,
            request_title,
            request_category_1,
            request_category_2,
            request_category_3,
            request_description,
        } = data;

        update(
            "task",
            {
                id,
                data: {
                    id,
                    taskInput: {
                        inbound_at: new Date(+inbound_at).toISOString(),
                        inbound_channel_category_1: "TASK_INBOUND_CHANNEL." + getCommonCode(inbound_channel_category_1),
                        inbound_channel_category_2:
                            getCommonCode(inbound_channel_category_1) + "." + getCommonCode(inbound_channel_category_2),
                        customer_inbound_count: "TASK_CUSTOMER_INBOUND_COUNT." + getCommonCode(customer_inbound_count),
                        customer_name,
                        customer_type: "TASK_CUSTOMER_TYPE." + getCommonCode(customer_type),
                        request_title,
                        request_category_1: "TASK_REQUEST_TYPE." + getCommonCode(request_category_1),
                        request_category_2: getCommonCode(request_category_1) + "." + getCommonCode(request_category_2),
                        request_category_3: getCommonCode(request_category_2) + "." + getCommonCode(request_category_3),
                        request_description,
                        update_userid: `${identity?.id}(${identity?.fullName})`,
                    },
                },
            },
            {
                onSuccess: () => {
                    notify("ra.notification.updated");
                    redirect("list", resource);
                },
            },
        );
    };

    return (
        <Edit id={id} title={translate("task.addTask")} actions={<EditActions />} redirect="list">
            <TaskInputs handleSubmit={handleSubmit} />
        </Edit>
    );
};
