import * as React from "react";
import { Create, useRedirect, useDataProvider, useGetIdentity, useRefresh } from "react-admin";
import { Dialog } from "@mui/material";
import { CrmDeal } from "../types";
import { DealInput } from "./DealInput";

export const DealCreate = ({ open }: { open: boolean }) => {
    const { identity } = useGetIdentity();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    if (!identity) return null;

    const handleClose = () => {
        redirect("/crm/deal");
    };

    const onSuccess = (deal: CrmDeal) => {
        redirect("/crm/deal");
        refresh();
        // increase the deal_status_order of all deals in the same status as the new deal
        dataProvider
            .getList("crm/deal", {
                pagination: { page: 1, perPage: 500 },
                sort: { field: "id", order: "ASC" },
                filter: { deal_status: deal.deal_status },
            })
            .then(({ data: deals }) =>
                Promise.all(
                    deals
                        .filter(oldDeal => oldDeal.id !== deal.id)
                        .map(oldDeal =>
                            dataProvider.update("crm/deal", {
                                id: oldDeal.id,
                                data: {
                                    dealInput: {
                                        id: oldDeal.id,
                                        deal_status_order: oldDeal.deal_status_order + 1,
                                    },
                                },
                                previousData: null,
                            }),
                        ),
                ),
            );
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Create
                title=" "
                resource="crm/deal"
                sx={{ "& .RaCreate-main": { mt: 0 } }}
                transform={({ client_id, deal_description, deal_status, deal_title, budget }: any) => {
                    return {
                        dealInput: {
                            client_id,
                            deal_description,
                            deal_status: "CRM_DEAL_STATUS." + deal_status,
                            deal_title,
                            budget,
                            create_userid: `${identity?.id}(${identity?.fullName})`,
                        },
                    };
                }}
                mutationOptions={{ onSuccess }}
            >
                <DealInput />
            </Create>
        </Dialog>
    );
};
