import { CreateUpdateFields, NestedCommonCodeFields } from "./fieldsCommon";

const Task = `
    id
    inbound_at
    inbound_channel_category_1 ${NestedCommonCodeFields}
    inbound_channel_category_2 ${NestedCommonCodeFields}
    customer_type ${NestedCommonCodeFields}
    customer_inbound_count
    customer_name
    request_title
    request_category_1 ${NestedCommonCodeFields}
    request_category_2 ${NestedCommonCodeFields}
    request_category_3 ${NestedCommonCodeFields}
    request_description
    response_department ${NestedCommonCodeFields}
    last_response_userid
    response_complete_at
    response_time_to_complete
    task_status ${NestedCommonCodeFields}
    task_status_order
    task_stash
    ${CreateUpdateFields}
`;

const TaskNote = `
    id
    task_id
    note
    ${CreateUpdateFields}
`;

export const fieldsTask = { Task, TaskNote };
