import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useGetList,
    useTranslate,
} from "react-admin";

export const DealInput = () => {
    const translate = useTranslate();
    const { data, isLoading } = useGetList("CommonCode", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "code_order", order: "ASC" },
        filter: { parent_code: "CRM_DEAL_STATUS" },
    });
    if (isLoading || !data) return null;

    const dealStatusChoices = data.map(item => {
        return {
            id: item.code,
            name: item.code_name,
        };
    });

    return (
        <SimpleForm>
            <TextInput label={translate("crm.dealName")} source="deal_title" fullWidth validate={[required()]} />
            <TextInput label={translate("common.description")} source="deal_description" multiline rows={3} fullWidth />
            <ReferenceInput source="client_id" reference="crm/client" validate={[required()]}>
                <AutocompleteInput
                    label={translate("crm.clientName")}
                    optionText="client_name"
                    validate={[required()]}
                    fullWidth
                />
            </ReferenceInput>
            <SelectInput
                label={translate("crm.crmStatus")}
                source="deal_status"
                choices={dealStatusChoices}
                fullWidth
                validate={[required()]}
                format={(code: string | { code: string }) => {
                    if (typeof code === "string") {
                        return code;
                    }
                    return code.code;
                }}
            />
            <NumberInput
                label={translate("crm.budget")}
                source="budget"
                fullWidth
                validate={[required()]}
                defaultValue={0}
            />
        </SimpleForm>
    );
};
