import { Divider, Tab, Tabs, Theme, useMediaQuery } from "@mui/material";
import {
    Datagrid,
    List,
    TextField,
    Pagination,
    useListContext,
    ListContextProvider,
    useTranslate,
    SearchInput,
    SelectInput,
} from "react-admin";
import { Fragment, useCallback, useEffect, useState } from "react";
import MobileLawGrid from "./MobileLawGrid";
import { ExternalLinkField } from "@src/components/fields";
import { BookmarkField } from "@src/components/fields/BookmarkField";

const defaultFilter = [<SearchInput source="search" alwaysOn />];
const choices = ["law", "admrul", "ordin", "prec", "decc", "detc"].map(type => ({
    id: type,
    name: `info_retrieval.types.${type}`,
}));

export const LawList = () => {
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [defaultFilters, setDefaultFilters] = useState<any>(defaultFilter);

    useEffect(() => {
        if (isXSmall) {
            setDefaultFilters([
                ...defaultFilter,
                <SelectInput
                    label="common.type"
                    source="type"
                    translateChoice={true}
                    choices={choices}
                    emptyValue="law"
                    alwaysOn
                />,
            ]);
        } else {
            setDefaultFilters(defaultFilter);
        }
    }, [isXSmall]);

    return (
        <List
            title="title.law"
            actions={false}
            filters={defaultFilters}
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            filterDefaultValues={{ type: "law", search: "방문판매" }}
            empty={false}
        >
            <TabbedDatagrid />
        </List>
    );
};

type lawDataType = "law" | "admrul" | "ordin" | "prec" | "detc" | "decc";

const TabbedDatagrid = () => {
    const translate = useTranslate();
    const listContext = useListContext();
    const { data, filterValues, setFilters, displayedFilters, isLoading } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [lawData, setLawData] = useState({
        law: [],
        admrul: [],
        ordin: [],
        prec: [],
        detc: [],
        decc: [],
    });

    useEffect(() => {
        if (isLoading) {
            return;
        }
        setLawData({ ...lawData, [filterValues.type]: data });
    }, [data, isLoading, filterValues.type]);

    const handleChange = useCallback(
        (_event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, type: value },
                    displayedFilters,
                    false, // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters],
    );

    const tabs = Object.keys(lawData).map(prop => ({ id: prop, name: translate(`law.${prop}.name`) }));

    return (
        <Fragment>
            {isXSmall ? (
                <ListContextProvider value={{ ...listContext, data: lawData[filterValues.type as lawDataType] }}>
                    <MobileLawGrid type={filterValues.type} />
                </ListContextProvider>
            ) : (
                <>
                    <Tabs
                        variant="fullWidth"
                        centered
                        value={filterValues.type}
                        indicatorColor="primary"
                        onChange={handleChange}
                    >
                        {tabs.map(choice => (
                            <Tab key={choice.id} label={choice.name} value={choice.id} />
                        ))}
                    </Tabs>
                    <Divider />
                    <ListContextProvider value={{ ...listContext }}>
                        <Datagrid rowClick="" bulkActionButtons={false}>
                            <TextField label="common.index" source="id" sortable={false} textAlign="center" />
                            <BookmarkField type={filterValues.type} label="" textAlign="center" />
                            <TextField
                                label={`law.${filterValues.type}.col1`}
                                source="col1"
                                sortable={false}
                                textAlign="center"
                            />
                            <TextField
                                label={`law.${filterValues.type}.col2`}
                                source="col2"
                                sortable={false}
                                textAlign="center"
                            />
                            <ExternalLinkField
                                label={`law.${filterValues.type}.title`}
                                sortable={false}
                                source="title"
                                textAlign="center"
                            />
                        </Datagrid>
                    </ListContextProvider>
                </>
            )}
        </Fragment>
    );
};
