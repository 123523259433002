import * as React from "react";
import { useState } from "react";
import { Box, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { DashboardMenuItem, MenuItemLink, MenuProps, useRedirect, useSidebarState, useTranslate } from "react-admin";
import BusinessIcon from "@mui/icons-material/Business";
import GavelIcon from "@mui/icons-material/Gavel";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import HomeIcon from "@mui/icons-material/Home";

import EmailIcon from "@mui/icons-material/Email";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ApprovalIcon from "@mui/icons-material/Approval";
import FolderIcon from "@mui/icons-material/Folder";
import WebIcon from "@mui/icons-material/Web";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

import SubMenu from "./submenus/SubMenu";
import { MenuName } from "./types";
import {
    CrmMenuItems,
    BusinessStatusMenuItems,
    AccountMgmtMenuItems,
    InfoRetrievalMenuItems,
    UnifiedInfoMenuItems,
    EcmMenuItems,
    OrganMenuItems,
    PaperMenuItems,
} from "./submenus";

const menuFontColor = "#4f5461";
// const menuFontColor = textPrimary;
const Menu = ({ dense = false }: MenuProps) => {
    const menuIconSize = 30;
    const translate = useTranslate();
    const [menuOpenState, setMenuOpenState] = useState({
        ui: false,
        businessStatus: false,
        accountMgmt: false,
        crm: false,
        if: false,
        ecm: false,
        system: false,
        relorgan: false,
        paperorgan: false,
    });
    const [open] = useSidebarState();
    const redirect = useRedirect();
    const handleMenuToggle = React.useCallback((menu: MenuName) => {
        setMenuOpenState(menuOpenState => ({ ...menuOpenState, [menu]: !menuOpenState[menu] }));
    }, []);

    return (
        <Box
            sx={{
                width: (theme: any) => {
                    if (open) {
                        return theme?.sidebar ? theme.sidebar : 220;
                    } else {
                        return 50;
                    }
                },
                mt: "20px",
                transition: (theme: any) =>
                    theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {/* RA DashboardMenuItem 컴포넌트 스타일링 제약으로인해 Home 메뉴를 별도로 만들었습니다  */}
            {/* <DashboardMenuItem leftIcon={<HomeIcon />} /> */}
            {/* Home */}
            <MenuItem
                onClick={() => {
                    redirect("/");
                }}
            >
                {/* <ListItemIcon>
                    <HomeOutlinedIcon color="success" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/home.png"
                    sx={{
                        height: menuIconSize,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`ra.page.dashboard`)}
                </Typography>
            </MenuItem>

            {/* 이메일 */}
            <MenuItem
                onClick={() => {
                    window.open("https://mail.worksmobile.com");
                }}
            >
                {/* <ListItemIcon>
                    <EmailOutlinedIcon color="success" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/email.png"
                    sx={{
                        height: menuIconSize,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.groupware_email`)}
                </Typography>
            </MenuItem>
            {/* 전자결재 */}
            <MenuItem
                onClick={() => {
                    window.open("https://macco.ncpworkplace.com/v/approval/home");
                }}
            >
                {/* <ListItemIcon>
                    <BorderColorOutlinedIcon color="success" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/approval.png"
                    sx={{
                        height: menuIconSize,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.groupware_approval`)}
                </Typography>
            </MenuItem>
            {/* 게시판 */}
            <MenuItem
                onClick={() => {
                    window.open("https://board.worksmobile.com");
                }}
            >
                {/* <ListItemIcon>
                    <AssignmentOutlinedIcon color="success" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/board.png"
                    sx={{
                        height: menuIconSize,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.groupware_bbs`)}
                </Typography>
            </MenuItem>
            {/* 주소록 <- crm */}
            <MenuItem
                onClick={() => {
                    window.open("https://contact.worksmobile.com/v2/p/shared/contact");
                }}
            >
                {/* <ListItemIcon>
                    <AssignmentIndOutlinedIcon color="success" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/contact.png"
                    sx={{
                        height: menuIconSize,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.crm`)}
                </Typography>
            </MenuItem>
            {/* 문서관리 ecm? */}
            <MenuItem
                onClick={() => {
                    window.open("https://drive.worksmobile.com/#/index");
                }}
            >
                {/* <ListItemIcon>
                    <CloudDoneOutlinedIcon color="success" />
                </ListItemIcon> */}
                <Box
                    component="img"
                    src="/assets/menuicon/drive.png"
                    sx={{
                        height: menuIconSize,
                        cursor: "pointer",
                        mr: 1, // margin-right
                    }}
                />
                <Typography variant="inherit" color="textPrimary">
                    {translate(`title.doc_mgmt`)}
                </Typography>
            </MenuItem>
            {/* 통합검색, ui(unified infomation) */}
            {/* <MenuItemLink
                to="search"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.ui_search")}
                leftIcon={<ManageSearchIcon sx={{ fontSize: "1.25rem" }} />}
                sx={{ pl: "12px" }}
            /> */}
            {/* 업무별 주요 현황 */}
            <SubMenu
                name="businessStatus"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.businessStatus}
                // icon={<InsertChartOutlinedIcon color="primary" />}
                dense={dense}
            >
                <BusinessStatusMenuItems dense={dense} />
            </SubMenu>
            {/* 주요 추진 업무, task */}
            {/* <MenuItemLink
                to="task"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.task")}
                leftIcon={<CalendarViewWeekIcon sx={{ fontSize: "1.25rem" }} />}
                sx={{ pl: "12px" }}
            /> */}
            {/* 정보검색, ir(information retrieval) */}
            <SubMenu
                name="if"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.if}
                // icon={<FindInPageOutlinedIcon color="primary" />}
                dense={dense}
            >
                <InfoRetrievalMenuItems dense={dense} />
            </SubMenu>
            {/* 관계기간 홈페이지 */}
            <SubMenu
                name="relorgan"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.relorgan}
                // icon={<LanguageOutlinedIcon color="primary" />}
                dense={dense}
            >
                <OrganMenuItems dense={dense} />
            </SubMenu>
            {/* 업계지 홈페이지 */}
            <SubMenu
                name="paperorgan"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.paperorgan}
                // icon={<LanguageOutlinedIcon color="primary" />}
                dense={dense}
            >
                <PaperMenuItems dense={dense} />
            </SubMenu>
            {/* 자금관리 */}
            <SubMenu
                name="accountMgmt"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.accountMgmt}
                // icon={<InsertChartOutlinedIcon color="primary" />}
                dense={dense}
            >
                <AccountMgmtMenuItems dense={dense} />
            </SubMenu>
        </Box>
    );
};

export default Menu;
