import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HomeIcon from "@mui/icons-material/Home";
import { Title, useTranslate, Button } from "react-admin";
import { Link } from "react-router-dom";

export default () => {
    const translate = useTranslate();
    return (
        <Card
            sx={{ border: "none", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}
        >
            <Title title="404 Not Found" />
            <CardContent sx={{ textAlign: "center" }}>
                <div style={{ opacity: 0.5 }}>
                    <ErrorOutlineIcon sx={{ width: "150px", height: "150px" }} />
                    <h1>404: Page not found</h1>
                    {translate("ra.message.not_found")}
                </div>
                <div style={{ marginTop: "2em" }}>
                    <Button
                        component={Link}
                        to={{
                            pathname: "/",
                            state: { _scrollToTop: true },
                        }}
                        label="action.go_home"
                        variant="contained"
                        size="medium"
                        children={<HomeIcon />}
                    />
                </div>
            </CardContent>
        </Card>
    );
};
