import React from "react";
import {
    RichTextInput,
    DefaultEditorOptions,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
} from "ra-input-rich-text";
import { RichTextInputImageButton } from "./RichTextInputImageButton";
import Image from "@tiptap/extension-image";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import { fontFamily } from "@src/layout/themes";

const TipTapEditorToolbar = () => {
    const size = "small";
    return (
        <RichTextInputToolbar>
            <LevelSelect size={size} />
            <FormatButtons size={size} />
            <ListButtons size={size} />
            <LinkButtons size={size} />
            <QuoteButtons size={size} />
            <ClearButtons size={size} />
            <RichTextInputImageButton size={size} />
        </RichTextInputToolbar>
    );
};

export const RichTextInputWithImageAttachment = ({
    label,
    name,
    source,
    defaultValue = "",
    setEditor,
    handleChange,
    validate = null,
}: any) => {
    const ff = fontFamily;
    const TipTapEditorOptions = {
        ...DefaultEditorOptions,
        onCreate: ({ editor }: any) => {
            if (setEditor) {
                editor.commands.setFontFamily(ff);
                setEditor(editor);
            }
        },
        extensions: [
            ...DefaultEditorOptions.extensions,
            Image.configure({
                allowBase64: true,
                inline: true,
            }),
            TextStyle,
            FontFamily,
        ],
    };
    return (
        <RichTextInput
            label={label}
            name={name}
            source={source}
            defaultValue={defaultValue}
            validate={validate}
            onChange={handleChange}
            editorOptions={TipTapEditorOptions}
            toolbar={<TipTapEditorToolbar />}
            fullWidth
        />
    );
};
