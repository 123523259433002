import { DefaultTheme } from "@mui/private-theming";

/* color scheme */
const maccoGreen = "#42b6be";
const maccoBlue = "#127bb6";
// const color3 = "#334257";

const defaultDarkBg = "#191919";
const defaultLightBg = "#f4f6f9"; //ender에서 제안한 색감

const sidebarDarkBg = "#191919"; //ender에서 제안한 색감
const sidebarLightBg = "#f9fafa";

// AppBar 배경색
const appBarDarkBg = "#202124";
//const appBarLightBg = "primary";
const appBarLightBg = "#2f58aa";

// Menu
const menuFontSize = "15px";
const menuIconSize = "1.5rem";

export const fontFamily =
    "Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif";

export const getNivoTheme = (theme: any) => {
    const mode = theme?.palette?.mode;
    const color = mode == "dark" ? "#efefef" : "#41444a";
    return {
        textColor: color,
        background: mode == "dark" ? defaultDarkBg : "#ffffff",
        axis: {
            fontSize: "14px",
            tickColor: color,
            ticks: {
                line: {
                    stroke: "#555555",
                },
                text: {
                    fill: color,
                },
            },
            legend: {
                text: {
                    fill: color,
                },
            },
        },
        tooltip: {
            container: {
                color: color,
                border: mode == "dark" ? "1px solid #191919" : "1px solid #efefe",
                background: mode == "dark" ? "#191919" : "#fff",
            },
        },
        legends: {
            title: {
                text: color,
            },
            text: {
                fill: color,
            },
        },
    };
};

const commonProps = {
    shape: {
        borderRadius: 10,
    },
    typography: {
        fontFamily,
        fontSize: 14,
        h1: {
            fontSize: "3rem",
        },
        h2: {
            fontSize: "2.125rem",
        },
        h3: {
            fontSize: "1.5rem",
        },
        h4: {
            fontSize: "1.25rem",
        },
    },
    // sidebar: {
    //     width: 220,
    //     backgroundColor: sidebarLightBg,
    // },
};

const commonComponentsProps = {
    MuiMenuItem: {
        styleOverrides: {
            root: {
                "& p.MuiTypography-root": {
                    // Menu fontSize, lineHeight
                    fontSize: menuFontSize,
                    lineHeight: "2.3",
                    // backgroundColor: "#ffffff", bg color 적용이 안됨 //sbjang
                },
                "& svg.MuiSvgIcon-root": {
                    fontSize: menuIconSize,
                },
            },
        },
    },
    MuiDrawer: {
        styleOverrides: {
            root: {
                "& .RaSidebar-paperAnchorLeft": {
                    border: "none",
                },
            },
        },
    },
    MuiLinearProgress: {
        styleOverrides: {
            colorPrimary: {
                // backgroundColor: "#f5f5f5",
                backgroundColor: "#f77003",
            },
            barColorPrimary: {
                backgroundColor: "#d7d7d7",
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                "&:hover:active::after": {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: "block",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "currentColor",
                    opacity: 0.3,
                    borderRadius: "inherit",
                },
            },
        },
    },
    MuiSnackbarContent: {
        styleOverrides: {
            root: {
                border: "none",
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: "standard",
        },
    },
    RaLoadingIndicator: {
        styleOverrides: {
            root: {
                minWidth: "50px",
            },
        },
    },
    RaSelectInput: {
        styleOverrides: {
            root: {
                marginTop: 0,
            },
        },
    },
};

export const lightTheme: DefaultTheme = {
    ...commonProps,
    palette: {
        primary: {
            main: maccoGreen,
        },
        secondary: {
            main: maccoBlue,
        },
        background: {
            default: defaultLightBg,
        },
        mode: "light" as const,
    },
    sidebar: {
        width: 220,
        // backgroundColor: sidebarLightBg,
    },
    components: {
        ...commonComponentsProps,
        RaSidebar: {
            styleOverrides: {
                root: {
                    backgroundColor: sidebarLightBg,
                    // "&.RaSidebar-fixed": {
                    //     backgroundColor: sidebarLightBg,
                    // },
                },
            },
        },
        RaFileInput: {
            styleOverrides: {
                root: {
                    "& .RaFileInput-dropZone": {
                        borderRadius: 10,
                        border: "1px solid #878787",
                        color: "#878787",
                    },
                    "& .RaFileInput-preview a": {
                        color: "#878787",
                        textDecoration: "none",
                    },
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    paddingLeft: "12px",
                    fontSize: "0.875rem",
                    borderLeft: `5px solid ${defaultLightBg}`,
                    "& .RaMenuItemLink-icon": {
                        minWidth: "36px",
                    },
                    "&.RaMenuItemLink-active": {
                        borderLeft: `5px solid ${maccoGreen}`,
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    "&.RaAppBar-toolbar": {
                        border: "none",
                        //color: defaultDarkBg,
                        color: defaultLightBg, //appbar의 컬러를 변경
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: "#FFF", // dark_mode color
                    backgroundColor: appBarLightBg,
                    border: "none",
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&$disabled": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: "none",
                },
                root: {
                    border: "1px solid #e0e0e3",
                    backgroundClip: "padding-box",
                },
            },
        },
    },
};

export const darkTheme: DefaultTheme = {
    ...commonProps,
    palette: {
        primary: {
            main: maccoGreen,
        },
        secondary: {
            main: maccoBlue,
        },
        background: {
            default: defaultDarkBg,
        },
        mode: "dark" as const, // Switching the dark mode on is a single property value change.
    },
    sidebar: {
        width: 220,
        backgroundColor: sidebarDarkBg,
    },
    components: {
        ...commonComponentsProps,
        RaSidebar: {
            styleOverrides: {
                root: {
                    "&.RaSidebar-fixed": {
                        backgroundColor: sidebarDarkBg,
                    },
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    paddingLeft: "12px",
                    fontSize: "0.875rem",
                    borderLeft: `5px solid ${defaultDarkBg}`,
                    "& .RaMenuItemLink-icon": {
                        minWidth: "36px",
                    },
                    "&.RaMenuItemLink-active": {
                        borderLeft: `5px solid ${maccoGreen}`,
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    "&.RaAppBar-toolbar": {
                        border: "none",
                        color: defaultLightBg,
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: "#000", // dark_mode color
                    backgroundColor: appBarDarkBg,
                    border: "none",
                },
            },
        },
        RaFileInput: {
            styleOverrides: {
                root: {
                    "& .RaFileInput-dropZone": {
                        borderRadius: 10,
                        border: "1px solid #c5c5c5",
                        color: "#c5c5c5",
                    },
                    "& .RaFileInput-preview a": {
                        color: "#c5c5c5",
                        textDecoration: "none",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: "none",
                },
                root: {
                    border: "1px solid #373737",
                    backgroundClip: "padding-box",
                },
            },
        },
    },
};
