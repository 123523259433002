import React from "react";
import { FunctionField, TextField, useRecordContext } from "react-admin";
import { Card, Box, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { TaskAvatar } from "./TaskAvatar";
import { CommonCodeDesc } from "@src/types";

export const TaskCard = ({ index = 0, setShowInfo = null }: { index?: number; setShowInfo?: any }) => {
    const record = useRecordContext();
    if (!record) return null;

    const handleClick = () => {
        if (setShowInfo) {
            setShowInfo({
                open: true,
                id: record.id,
            });
        }
    };

    return (
        <Draggable draggableId={String(record.id)} index={index}>
            {(provided, snapshot) => (
                <Box
                    sx={{ marginBottom: 1 }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={handleClick}
                >
                    <Card
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging ? "rotate(-2deg)" : "",
                        }}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                        <Box padding={1} display="flex">
                            <TaskAvatar dept={record?.response_department?.code} />
                            <Box sx={{ marginLeft: 1 }}>
                                <Typography variant="body2" gutterBottom>
                                    <TextField source="request_title" />
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    <TextField source="customer_name" />
                                    {", "}
                                    <FunctionField
                                        render={({
                                            customer_inbound_count,
                                            request_category_1,
                                        }: {
                                            customer_inbound_count: CommonCodeDesc;
                                            request_category_1: CommonCodeDesc;
                                        }) => {
                                            return `${customer_inbound_count.code_name}, ${request_category_1.code_name}`;
                                        }}
                                    />
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            )}
        </Draggable>
    );
};
