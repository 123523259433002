import React, { useEffect, useState } from "react";
import { Button, Title, TopToolbar, useDataProvider, useNotify } from "react-admin";
import { AddMemberInputs } from "./AddMemberInputs";
import { DeleteMemberInputs } from "./DeleteMemberInputs";
import AddIcon from "@mui/icons-material/Add";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

export const AccountManager = () => {
    const dataProvider = useDataProvider();
    const [mode, setMode] = useState("add_member");
    const [members, setMembers] = useState<{ emailId: string; doorayId: string }[]>([]);
    const [departments, setDepartments] = useState<{ name: string; groupPath: string }[]>([]);
    const [jobRanks, setJobRanks] = useState<{ id: string; name: string }[]>([]);
    const [refetch, setRefetch] = useState<boolean>(false);
    const notify = useNotify();

    useEffect(() => {
        const setDoorayMembers = async () => {
            // dooray 멤버정보 요청
            const resMembers = await dataProvider.callExternalApi("/dooray/getMembers");

            if (resMembers?.data == null || resMembers?.data?.header?.isSuccessful === false) {
                notify("notification.account_mgmt.failed_to_select_members", { type: "warning" });
                return;
            }

            // emailId(idProviderUserId), dooray 멤버 ID 정보 저장
            const members = resMembers.data.result.map((account: any) => ({
                emailId: account.idProviderUserId,
                doorayId: account.id,
            }));
            setMembers(members);

            // dooray 부서 정보 요청
            const resDepts = await dataProvider.callExternalApi("/dooray/getDepartments");

            if (resDepts?.data == null || resDepts?.data?.header?.isSuccessful === false) {
                notify("notification.account_mgmt.failed_to_select_departments", { type: "warning" });
                return;
            }

            const deptInfos = resDepts.data.result
                .map((dept: any) => ({
                    name: dept.name,
                    externalKey: dept.externalKey,
                    id: dept.id,
                    parentId: dept.parentDepartment.id,
                }))
                .filter((dept: any) => dept.name !== "GUEST"); // GEUST 항목 제거

            const getGroupPath = (department: any, path: string): string => {
                const parentId = department.parentId;
                if (parentId === null) return path;

                const parentDept = deptInfos.filter((dept: any) => dept.id === parentId)[0];

                path = parentDept.externalKey + "/" + path;
                return getGroupPath(parentDept, path);
            };

            const departments = deptInfos
                .map((dept: any) => {
                    const { name, externalKey } = dept;
                    return {
                        name,
                        groupPath: getGroupPath(dept, externalKey), // group depth 계산
                    };
                })
                .filter((dept: any) => dept.name !== "macco"); // macco 항목 제거
            setDepartments(departments);

            // dooray 직위 정보 요청
            const resJobRanks = await dataProvider.callExternalApi("/dooray/getJobRanks");

            if (resJobRanks?.data == null || resJobRanks?.data?.header?.isSuccessful === false) {
                notify("notification.account_mgmt.failed_to_select_jobRanks", { type: "warning" });
                return;
            }
            setJobRanks(
                resJobRanks.data.result.map((jobRank: any) => ({
                    name: jobRank.name,
                    id: jobRank.id,
                })),
            );

            setRefetch(false);
        };
        setDoorayMembers();
    }, [refetch]);

    return (
        <>
            <Title title={`title.dooray_${mode}`} />
            <TopToolbar>
                {mode === "add_member" && (
                    <Button
                        label={"title.dooray_delete_member"}
                        children={<PersonRemoveIcon />}
                        onClick={() => {
                            setMode("delete_member");
                        }}
                    />
                )}
                {mode === "delete_member" && (
                    <Button
                        label={"title.dooray_add_member"}
                        children={<AddIcon />}
                        onClick={() => {
                            setMode("add_member");
                        }}
                    />
                )}
            </TopToolbar>
            {mode === "add_member" && (
                <AddMemberInputs
                    members={members}
                    departments={departments}
                    jobRanks={jobRanks}
                    setRefetch={setRefetch}
                />
            )}
            {mode === "delete_member" && <DeleteMemberInputs members={members} setRefetch={setRefetch} />}
        </>
    );
};
